.modal-import .ant-modal .ant-modal-content {
  background: #fafafa;
}

.modal-import .ant-modal .ant-modal-header {
  background: #fafafa;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 700;
    margin: 5px 0px;
  }

  .list {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;

    .leftSide {
      display: flex;
      align-items: center;
      flex-grow: 1;

      p {
        color: black;
        font-weight: 600;
        margin: 15px 0px;
        margin-left: 10px;
      }
    }
  }

  .points_used {
    color: var(--Nutrals-100, #051123);
    /* Title/Title medium */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.024px;
  }

  .points_used_body {
    color: var(--Nutrals-300, #5e6c84);
    /* Body/Body small */
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 160% */
    letter-spacing: 0.04px;
  }

  .Button {
    margin: 50px;
    border-radius: 8px;
    height: 45px;
    color: white;
    // background-color: #7043ff;
    border: 0.5px solid #7043ff;
    color: #7043ff;
    height: 42px;
    width: 100px;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
  }

  .Button:hover {
    color: #7043ff !important;
    border: 0.5px solid #7043ff;
  }

  .switchItem {
    display: flex;
    align-items: center;
    margin: 10px 0px;
  }

  .switchLabel {
    margin-left: 10px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 600;
    // margin-top: 10px;
  }
}

.loading {
  display: flex;
  align-items: center;

  min-height: 534px;
}

.switchItem {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.switchLabel {
  margin-left: 10px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  // margin-top: 10px;
}

.download_CSV {
  margin-top: 10px;

  Button {
    background-color: #7043ff;
    color: white;
    border-radius: 8px;
    height: 40px;
  }

  Button:hover {
    background-color: #8964ff;
    color: white !important;
  }
}

.import-page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dfe1e6;
    padding: 10px 24px;
    font-size: 16px;
    font-weight: 600;

    &__left {
      display: flex;
      align-items: flex-start;
      align-items: center;

      img {
        height: 30px;
        margin-right: 10px;
        width: 30px;
      }

      &__setting {
        color: #091e42;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;

        letter-spacing: 0.048px;
        cursor: pointer;
      }

      &__pageName {
        color: #091e42;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        text-transform: capitalize;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 32px;

      button {
        border: none;
        background: transparent;
        box-shadow: none;
        color: var(--primary-600-main, #7043ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.028px;
      }
    }
  }

  &__body {
    padding: 10px 10px 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    &__left {
      grid-column: 1/3;
      border: 1px solid #dfe1e6;
      border-radius: 10px;
      background: #fff;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &__right {
      padding: 24px 24px 0px 24px;
      border-radius: 10px;
      border: 1px solid #dfe1e6;
      background: #fff;
      overflow: auto;
      position: relative;
    }
  }
}

.modal {
  :global(.ant-modal-content) {
    background-color: #f9fafb !important;
    padding: 0px;
  }
  :global(.ant-modal-close) {
    top: 25px !important;
  }
}

.title,
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 16px 24px;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 1px solid #dfe1e6;
  .left {
    font-size: 20px;
  }
  .right {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-right: 40px;
  }
}

.footer {
  border-radius: 0px 0px 6px 6px;
  border-top: 1px solid #dfe1e6;
  border-bottom: none;

  .footerRight {
    display: flex;
    align-items: center;
    gap: 20px;
    .reset {
      color: #5e6c84;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.modalContentWrapper {
  // min-height: 78vh;
  // height: 78vh;
  display: flex;
  flex-direction: column;
}

.popoverContent {
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    font-weight: 600;
    margin: 0;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;

    .cancel {
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }

    .confirm {
      color: #7043ff;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
