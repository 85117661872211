.menuContainer {
  position: absolute;
  top: 16px;
  right: 0px;
  left: 0px;
  margin: auto;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 50px;
  border-radius: 50px;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
  gap: 14px;
  z-index: 999;
}

.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
  position: relative;
}

.iconContainer,
.iconContainerGray {
  position: relative;
  display: inline-flex;
  // padding: 3px; /* Space for border */
  border-radius: 10px; /* Rounded corners */
}

.iconContainer::before,
.iconContainerGray::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 10px; /* Adjust if needed */
  padding: 2px; /* Border thickness */
  background: linear-gradient(180deg, #c327d1, #7043ff);
  -webkit-mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.iconContainerGray::before {
  padding: 1px;
  background: #d1d1d1;
}

.icon {
  background-color: white;
  border-radius: 10px;
  padding: 6px;
  width: 43px;
  height: 43px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

// .icon {
//   background-color: white;
//   border: 1px solid #d1d1d1;
//   padding: 6px;
//   border-radius: 6px;
//   width: 43px;
//   height: 43px;
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
// }

.label {
  font-size: 12px;
  color: #333;
  margin-top: 4px;
}

.closeButton {
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
  height: fit-content;
  right: 20px;
  img {
    width: 10px;
    height: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.65;
    }
  }
}

.dropDownContent {
  width: 90%;
  margin-left: 5%;
  margin-top: 10px;
}

.listItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ofThe {
  color: #777;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.plusHeatbox {
  color: #7043ff;

  text-align: center;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filterBox {
  border-radius: 8px;
  background: rgba(222, 207, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterBoxInside {
  display: flex;
  align-items: center;
  width: 95%;
  height: 90%;
  justify-content: space-between;
}
.applyFilters {
  border: none !important;
  background-color: transparent !important;
  &:hover {
    color: #7043ff !important;
    border: none;
    box-shadow: none;
    background-color: transparent !important;
  }
}
.filterDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #7043ff;
  position: absolute;
  top: 0;
  right: 0;
}

.listItemActions {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  img {
    width: 12px;
  }
}
