.typing-animation {
  display: inline-block;
  overflow: hidden;
  position: relative;
}
.typing-animation::after {
  content: '';
  position: absolute;
  right: -4px;
  animation: typing 1.5s infinite;
}
@keyframes typing {
  0% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
  100% {
    content: '.';
  }
}
