.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px;
  border-radius: 10px;
  border: 1px solid;
  font-size: 12px;
  gap: 10px;
  cursor: default;

  .label {
    font-weight: 500;
    color: #172b4d;
  }

  .amount {
    font-weight: 500;
    color: #6b778c;
  }
}

.grayBorder {
  border-color: #dfe1e6;
}

.redBorder {
  border-color: #ff4d4f;
}

.container:hover {
  background-color: #f9f9f9;
}
