@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tailwind CSS directives */

* {
  font-family: Roboto, sans-serif, -apple-system;
}

.twp {
  font-family: ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --sidebar-background: 0 0% 98%;

    --sidebar-foreground: 240 5.3% 26.1%;

    --sidebar-primary: 240 5.9% 10%;

    --sidebar-primary-foreground: 0 0% 98%;

    --sidebar-accent: 240 4.8% 95.9%;

    --sidebar-accent-foreground: 240 5.9% 10%;

    --sidebar-border: 220 13% 91%;

    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }

  /* Smooth scrolling for entire page */
  html {
    scroll-behavior: smooth;
  }
}

/* Hide scrollbar but allow scrolling */
.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Animation delays for thinking animation */
.animation-delay-200 {
  animation-delay: 0.2s;
}

.animation-delay-400 {
  animation-delay: 0.4s;
}

/* Custom animations */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fade-in 0.3s ease-out;
}

/* Enhanced accordion animations */
@keyframes accordion-down {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
    opacity: 1;
  }
}

@keyframes accordion-up {
  from {
    height: var(--radix-accordion-content-height);
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}

.animate-accordion-down {
  animation: accordion-down 200ms cubic-bezier(0.16, 1, 0.3, 1);
}

.animate-accordion-up {
  animation: accordion-up 200ms cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes bounce-behind {
  /* When going left-to-right */
  0% {
    left: -10px;
  } /* start 10px left of black dot */
  25% {
    left: 14px;
  } /* bounce quickly to 10px right */
  35% {
    left: 0px;
  } /* bounce back to center (touching black dot) */
  50% {
    left: 9px;
  } /* bounce back to center (touching black dot) */
  70% {
    left: 18px;
  } /* bounce to 18px right again */
  100% {
    left: 18px;
  } /* hold at 18px right for pause */
}

.purple-dot {
  position: absolute;
  left: 4px;
  top: 4px;
}

/* Original styles below */
/* @font-face {
  font-family: 'FbSpoilerPasEng';
  src: url('/src/fonts/FbSpoilerPasEng-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FbSpoilerPasEng';
  src: url('/src/fonts/FbSpoilerPasEng-Medium.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FbSpoilerPasEng';
  src: url('/src/fonts/FbSpoilerPasEng-Light.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
} */

@font-face {
  font-family: 'FbSpoilerPasEng';
  src: url('/src/fonts/FbSpoilerPasEng-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'FbSpoilerPasEng';
  src: url('/src/fonts/FbSpoilerPasEng-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FbSpoilerPasEng';
  src: url('/src/fonts/FbSpoilerPasEng-Medium.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  font-family: BlinkMacSystemFont, -apple-system, 'FbSpoilerPasEng', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #d2d2d2;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #091e42;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

code {
  font-family: 'Roboto';
}

.ant-btn-default:disabled,
.ant-btn-primary:disabled {
  background-color: transparent;
}

.ant-btn-primary:disabled {
  color: white;
  background-color: var(--color-gray-01);
}

.ant-btn-default {
  color: var(--color-blue-03);
  border-color: var(--color-blue-03);
}
