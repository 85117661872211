.container {
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.keyIcon {
  font-size: 20px;
  margin-right: 8px;
  color: #1a1a1a;
}

.title {
  margin: 0 !important;
}

.actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.docsLink {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #7043ff;
  font-weight: 500;
}

.generateButton {
  background-color: #7043ff;
  border-color: #7043ff;
  border-radius: 20px;
  padding: 0 24px;
  height: 40px;
  font-weight: 500;

  &:hover,
  &:focus {
    background-color: #5248c6;
    border-color: #5248c6;
  }
}

.table {
  width: 100%;

  :global {
    .ant-table-thead > tr > th {
      background-color: #f8f9fa;
      color: #6c757d;
      font-weight: 500;
      padding: 16px 24px;
    }

    .ant-table-tbody > tr > td {
      padding: 16px 24px;
      border-bottom: 1px solid #f0f0f0;
    }

    .ant-table-tbody > tr:last-child > td {
      border-bottom: none;
    }
  }
}

.deleteButton {
  color: #ff4d4f;

  &:hover {
    background-color: rgba(255, 77, 79, 0.1);
  }
}

// Empty state styling
:global(.ant-empty) {
  margin: 48px 0;
}
