.engagement-count {
  transition: all 0.5s ease-in-out;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.engagement-count-animate {
  animation: pulse 0.5s ease-in-out;
}
