/* Google Login Button */
.gsi-material-button {
  /* -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none; */
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 0;
  flex-grow: 0;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}
/* End of Google Login Button */

.cabmCustomSidebar {
  height: auto;
  min-height: 100vh;
  z-index: 2000;
  position: sticky;
  top: 0;
  max-height: 100vh;
}

.cabmCustomSidebar li {
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.w-full-filter-sidebar {
  width: calc(100% - 265px);
}

.companiesFooterOpenedSidebar {
  width: calc(100% - 64px - 265px) !important;
}

.cabmCustomSidebar .ant-menu-item {
  margin: 0px;
  padding-left: 10px;
  width: auto;
  overflow: unset;
}
.bottomSideBar .ant-menu-item {
  /* margin: 0px; */
  padding-top: 3px !important;
  padding-left: 6px !important;
  /* width: auto; */
  /* overflow: unset; */
}

.cabmCustomSidebar .ant-menu-item.ant-menu-item-active {
  background-color: rgba(255, 255, 255, 20%) !important;
}

.cabmCustomSidebar .ant-menu-item-selected.ant-menu-item.ant-menu-item-active {
  background: var(--color-white) !important ;
}

.cabmCustomSidebar .buy_pointParent .ant-menu-item.ant-menu-item-active {
  /* background-color: rgba(255, 255, 255, 20%) !important; */
  background-color: transparent !important;
}

.cabmCustomSidebar .logoParent .ant-menu-item.ant-menu-item-active {
  background-color: transparent !important;
}

.cabmCustomSidebar .ant-menu-item-selected,
.cabmCustomSidebar .ant-menu-item-selected.ant-menu-item.ant-menu-item-active {
  background: var(--color-white);
  border-radius: 8px;
  color: black;
}

.fancyTable {
  width: 100%;
}

.fancyTable .ant-table-body {
  min-height: var(--min-fancy-table-height);
  overflow-x: hidden !important;
}
.noFancy .fancyTable .ant-table-body {
  min-height: unset;
  max-height: unset !important;
  overflow-x: hidden !important;
}
.fullHeightFilters {
  max-height: unset !important;
}
.noFancy {
  height: 97% !important;
  overflow-y: hidden;
}
/* .fancyTable .ant-table-body:hover {
  overflow-x: scroll !important;
} */

.fancyTable th.ant-table-cell {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: var(--color-blue-05) !important;
}

.fancyTable th.ant-table-cell th.ant-table-cell-row-hover {
  cursor: pointer;
}

.fancyTable-row {
  cursor: pointer;
}

.fancyTable .connectPlusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fancyTable .tblLogoName {
  display: flex;
  align-items: center;
}
.fancyTable .tblLogoName .badge,
.fancyTable .tblLogoName .badgeFirst,
.fancyTable .tblLogoName .badgeSecond,
.fancyTable .tblLogoName .badgeThird {
  width: 24px;
  height: 24px;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fancyTable .tblLogoName .badge {
  background: #7043ff;
}

.fancyTable .tblLogoName .badgeFirst {
  border: 2px solid #50e2ba !important;
}
.fancyTable .tblLogoName .badgeFirst .badgeFirst {
  background: #50e2ba;
}

.fancyTable .tblLogoName .badgeSecond {
  border: 2px solid #7043ff !important;
}

.fancyTable .tblLogoName .badgeSecond .badgeSecond {
  background: #7043ff;
}

.fancyTable .tblLogoName .badgeThird {
  border: 2px solid #ff4fe6 !important;
}

.fancyTable .tblLogoName .badgeThird .badgeThird {
  background: #ff4fe6;
}

.fancyTable .tblLogoName .badge img {
  margin-right: 1px;
}

.fancyTable .tblLogoName span {
  letter-spacing: 0.005em;
}
.fancyTable .tblLogoName .mainPicture {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 0.5px solid #939cad45;
}
.fancyTable .tblLogoName .ant-badge {
  border-radius: 50%;
  border: 2px solid #7143ff;
  height: 52px;
  width: 52px;
}
.fancyTable .fundingFlag {
  font-size: 10px;
  color: #ffffff;
  margin-inline-end: 0;
}

.Tiles_radio {
  position: absolute;
  right: 15px;
  top: 15px;
}
.Tiles_radio .ant-radio-inner {
  width: 20px;
  height: 20px;
}
.Tiles_radio .ant-radio-inner::after {
  content: url(../common/assets/svg/tick_radio.svg);
  transform: scale(1) !important;
  top: 7px;
  left: 6px;
}
.fancyTable .tblFieldText.company_field {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.fancyTable .tblFieldText.company_field img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border-radius: 15px;
}

.fancyTable .tblFieldText span {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.005em;
  color: var(--color-gray-02);
}

.fancyTable .tblFieldText span.asLink {
  color: var(--color-blue-03) !important;
}

.fancyTable .tblFieldText a {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.005em;
  color: var(--color-blue-03);
}

.fancyTable .tblFieldAccurasy p {
  width: 24px !important;
  height: 24px !important;
  border-radius: 72px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-white);
  text-align: center;
}

.fancyTable .tblFieldAccurasy .hight {
  background: var(--color-green-01);
}

.fancyTable .tblFieldAccurasy .medium {
  background: var(--color-green-02);
}

.fancyTable .tblFieldAccurasy .low {
  background: var(--color-red-01);
}

.fancyTable thead {
  height: 64px !important;
}

.fancyTable thead .ant-table-cell {
  background: var(--color-white) !important;
}

.marginTable {
  /* margin: 20px; */

  margin: 20px 20px 0px 20px;
  position: relative;
}

.marginTable::after {
  display: block;
  content: '';
  height: 60px;
}

.fancyTable table {
  width: 100% !important;
  background-color: var(--color-white);
}

.fancyTable th.ant-table-cell {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: var(--color-blue-05) !important;
}

.smallRoundPicture {
  margin-left: -3px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 0.5px solid #939cad45;
  z-index: 0;
}
.mediumRoundPicture {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.fancyTable .tblFieldText span {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.005em;
  color: var(--color-gray-02);
}

.fancyTable .tblFieldText span.asLink {
  color: var(--color-blue-03) !important;
}

.fancyTable .tblFieldText a {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.005em;
  color: var(--color-blue-03);
}

.fancyTable .tblContactInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  height: 40px;
  max-width: 135px;
  background: var(--color-blue-04);
  border-radius: 40px;
}

.fancyTable .tblFieldAccurasy p {
  width: 24px !important;
  height: 24px !important;
  border-radius: 72px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-white);
  text-align: center;
}

.fancyTable .tblFieldAccurasy .scoreAplus {
  background: var(--color-green-01);
}

.fancyTable .tblFieldAccurasy .scoreA {
  background: var(--color-green-02);
}

.fancyTable .tblFieldAccurasy .scoreBplus {
  background: var(--color-green-06);
}

.fancyTable .tblFieldAccurasy .scoreB {
  background: var(--color-green-07);
}

.fancyTable .tblFieldAccurasy .scoreCplus {
  background: var(--color-green-08);
}

.fancyTable .tblFieldAccurasy .scoreC {
  background: var(--color-red-03);
}

.fancyTable .tblFieldAccurasy .scoreDplus {
  background: var(--color-red-04);
}

.fancyTable .tblFieldAccurasy .scoreD {
  background: var(--color-red-01);
}

.fancyTable thead {
  height: 64px !important;
}

.fancyTable thead .ant-table-cell {
  background: var(--color-white) !important;
}
.fancyTable tbody tr {
  height: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: none !important;
}

/* .fancyTable tbody tr td {
  height: 64px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: none !important;
} */

.fancyDrawer .ant-drawer-header-title {
  display: table;
}

.fancyDrawer .ant-drawer-header-title .ant-drawer-close {
  float: right;
  position: inherit;
}

.fancyDrawer .ant-drawer-header.ant-drawer-header-close-only {
  padding-bottom: 0px !important;
  border-bottom: 0px;
}

.fancyDrawer .ant-drawer-body {
  padding: 36px 16px 16px 16px !important;
}

.extraFancyDrawer .ant-drawer-body {
  padding: 0px 16px !important;
}

.extraFancyDrawer {
  background: #f3f4f6 !important;
}

.extraFancyDrawer .ant-drawer-header {
  background-color: white !important;
}

.fancyDrawer .drawerTitleContact {
  width: auto;
  margin-top: 30px !important;
  position: relative;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 12px 0px;
}

.fancyDrawer .drawerTitleContact p {
  margin: 5px;
  color: #000;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.024px;
}

.fancyDrawer .drawerTitleContact img {
  display: table-cell;
  float: left;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 0.5px solid #939cad45;
}

.fancyDrawer .drawerTitle {
  display: table;
  width: auto;
  margin-top: -5px !important;
  position: absolute;
  display: table;
}

.fancyDrawer .drawerTitle img {
  display: table-cell;
  float: left;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 0.5px solid #939cad45;
}

.fancyDrawer .drawerTitle p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: initial;
  letter-spacing: 0.005em;
  color: var(--color-blue-05);
  padding-left: 16px;
}

.fancyDrawer .drawerTitle p a {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.004em;
  color: var(--color-blue-03);
  display: flex;
  padding-left: 16px;
}

.fancyDrawer .drawerTitle p span {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.004em;
  color: var(--color-gray-02);
  display: flex;
  padding-left: 16px;
}

.fancyDrawer .ant-drawer-header {
  border-bottom: 0px;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
}

.fancyAreaChart {
  width: auto !important;
}

.fancyAreaChart .recharts-cartesian-grid {
  border-radius: 8px !important;
}

.fancyAreaChart .recharts-wrapper {
  width: auto !important;
  display: flex !important;
  padding: 0px !important;
}

.fancyAreaChart .recharts-cartesian-axis-line {
  background: var(--color-green-05) !important;
}

.fancyAreaChart .recharts-curve.recharts-area-area {
  fill-opacity: 1 !important;
  fill: #7043ff !important;
}

.fancyAreaChart .recharts-cartesian-axis-ticks tspan {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: var(--color-gray-01);
}

.display_none {
  display: none !important;
}

.contactTypes {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0px 16px;
  width: auto;
  height: 32px;
  border: 1px solid var(--color-blue-03);
  border-radius: 8px !important;
  float: right;
  cursor: pointer;
  display: flex;
}

.contactTypes img {
  float: left;
  padding-right: 10px;
}

.contactTypes span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 35px;
  letter-spacing: 0.002em;
  color: var(--color-blue-03);
}

.contactTypesNoBorder {
  display: table;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 10px 16px;
  width: auto;
  height: 32px;
  float: right;
  margin-top: -5px;
  cursor: pointer;
  margin-left: 15px;
}

.contactTypesNoBorder img {
  float: left;
  padding-right: 10px;
  padding-top: 8px;
  margin-left: -20px !important;
}

.contactTypesNoBorder span {
  line-height: 32px !important;
  color: var(--color-blue-03) !important;
  font-weight: 500 !important;
}

.location {
  margin-right: 8px;
  margin-top: 5px;
  float: left;
}

/*Applying a class to a drawer results in puting that name to the children of this class so it cannot be easly modified*/
.ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.ant-drawer {
  z-index: 1;
}

.fancy-input {
  border: 0 solid transparent;
}

.location {
  margin-right: 8px;
  margin-bottom: -5px;
}

.c-side-filter .ant-slider-track {
  background-color: var(--color-blue-03) !important;
}

.c-side-filter .ant-slider-handle::after {
  background-color: #7043ff;
}

.c-side-filter .ant-slider-mark-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.005em;
  color: var(--color-gray-01);
}

.bodyPage {
  max-width: 80%;
}

.textTooltip {
  max-width: 200px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  color: var(--color-gray-02);
  font-weight: 400;
  line-height: 48px;
  padding-left: 16px;
}

.textTooltipLists {
  max-width: 150px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  padding-right: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: var(--color-gray-02);
  float: left;
}

.textTooltipContact {
  max-width: 175px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.textTooltipCompanies {
  max-width: 225px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-weight: 500 !important;
}

.textTooltipCompaniesTitle {
  max-width: 225px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-weight: bold !important;
}

.chainLogo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 6px;
}

.contentBigText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: var(--color-blue-05);
}

.imgOpacity {
  opacity: 0.3;
}

.ant-tree-switcher.ant-tree-switcher-noop {
  display: none;
}

.convrtFilter .ant-tree-treenode,
.convrtFilter .ant-tree-node-content-wrapper {
  width: 90%;
}

.convrtFilter .ant-select {
  height: 32px;
  background: var(--color-white);
  border: 1px solid var(--color-white-04);
  border-radius: 8px;
  width: 100%;
}

.convrtFilter .ant-tree-title {
  margin: 0px;
  padding: 0px;
}

.fltrMinMax .min {
  height: 32px;
  left: 0px;
  top: 0px;
  background: var(--color-white);
  border: 1px solid var(--color-white-04);
  border-radius: 8px;
  max-width: 65px;
  float: left;
}

.fltrMinMax .max {
  height: 32px;
  left: 0px;
  top: 0px;
  background: var(--color-white);
  border: 1px solid var(--color-white-04);
  border-radius: 8px;
  max-width: 65px;
  float: right;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background: var(--color-blue-04) !important;
  border: 1px solid var(--color-blue-03) !important;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked span {
  color: var(--color-blue-03) !important;
}

.cabmSpin {
  display: inherit;
  margin-top: 50%;
}

.eventsSpin {
  display: block;
  margin-top: 25%;
}

.cabmContactSpin {
  display: inherit;
  margin-top: 20%;
  text-align: center;
}

.cabmContactSpin .ant-spin-dot.ant-spin-dot-spin {
  margin: 0px auto;
}

.cabmTabsCompanyDetails {
  margin-top: -12px;
  width: 100%;
  position: relative;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.cabmTabsCompanyDetails .ant-tabs-nav-wrap {
  border-bottom: 1px solid #f3f4f6;
}

.cabmTabsCompanyDetails .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.065px;
  color: var(--color-gray-06);
  max-width: 100px;
  white-space: normal;
}

.cabmTabsCompanyDetails .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-600-main, #7043ff);
  text-align: center;
  font-family: 'Roboto';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.065px;
}

.cabmTabsCompanyDetails .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  margin: 0 0 5px 22px;
  max-width: 100px;
}

.blockField {
  width: 191px;
  height: 17px;
  display: block;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0.81%,
    rgba(235, 236, 240, 0.266757) 9.77%,
    rgba(235, 236, 240, 0.388778) 20.66%,
    rgba(235, 236, 240, 0.498779) 30.74%,
    rgba(235, 236, 240, 0.652981) 44.87%,
    rgba(235, 236, 240, 0.8) 56.78%,
    rgba(235, 236, 240, 0.777582) 66.37%,
    rgba(235, 236, 240, 0.752) 77.3%,
    rgba(244, 245, 247, 0.409832) 88.8%,
    rgba(255, 255, 255, 0) 105.38%
  );
}

.blockFieldCompany {
  float: left;
  width: 70%;
  height: 17px;
  display: block;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0.81%,
    rgba(235, 236, 240, 0.266757) 9.77%,
    rgba(235, 236, 240, 0.388778) 20.66%,
    rgba(235, 236, 240, 0.498779) 30.74%,
    rgba(235, 236, 240, 0.652981) 44.87%,
    rgba(235, 236, 240, 0.8) 56.78%,
    rgba(235, 236, 240, 0.777582) 66.37%,
    rgba(235, 236, 240, 0.752) 77.3%,
    rgba(244, 245, 247, 0.409832) 88.8%,
    rgba(255, 255, 255, 0) 105.38%
  );
}

.proFeatures {
  height: 21px;
  border: 0.5px solid var(--color-blue-03);
  border-radius: 6px;
  cursor: pointer;
  float: right;
  margin-top: -15px;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.proFeatures img {
  position: relative;
  margin-right: 5px;
  float: left;
}

.proFeatures span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.002em;
  color: var(--color-blue-03);
  display: initial;
  padding-bottom: 23px;
}

.soon {
  font-weight: 600;
  font-style: italic;
  color: var(--color-blue-05);
  margin-top: -15px;
}

.noData {
  margin: 20% auto;
}

.linkTo {
  cursor: pointer;
}

.accessTelegram {
  height: 40px;
  background: var(--color-blue-04);
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  width: fit-content;
  cursor: pointer;
}

.accessTelegram span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 38px;
  letter-spacing: 0.005em;
  color: var(--color-blue-03);
}

.accessTelegram img {
  margin-top: 7px;
  float: left;
}

.emptyContacts {
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: 15%;
}

.emptyContacts p {
  width: 100%;
  text-align: center;
}

.emptyContacts p span {
  width: 368px;
  height: 64px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: var(--color-blue-05);
}

.emptyContacts .research {
  height: 31px;
  left: 695px;
  top: 439px;
  background: var(--color-blue-03);
  border-radius: 6px;
  width: fit-content;
  display: block;
  margin: 0px auto;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.002em;
  color: var(--color-white);
  padding-left: 40px;
  padding-right: 40px;
}

.progressBar .ant-progress-inner,
.progressBar .ant-progress-bg {
  height: 30px !important;
}

.progressBar .ant-progress-bg {
  background-color: var(--color-green-01) !important;
}

.buyMore {
  width: 92px !important;
  height: 32px !important;
  background: var(--color-blue-03);
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.002em;
  color: var(--color-white);
  display: block;
  margin-left: 12px;
  margin-top: 0px;
  text-align: center;
  cursor: pointer;
}

.moveRight {
  display: flex;
  justify-content: right;
  padding-right: 25px;
  padding-top: 20px;
}

.widthAuto {
  width: auto;
  float: left;
  margin-top: 0px;
}

.ant-pagination-options {
  display: none !important;
}

.modalExport {
  max-width: 400px;
}

.modalExport .ant-modal-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-black);
}

.modalExport p {
  display: flex;
  cursor: pointer;
}

.modalExport p img {
  width: 27px;
  height: 27px;
  float: right;
  margin-right: 16px;
  margin-top: 5px;
}

.modalExport p span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.005em;
  color: var(--color-black);
}

.fancyTable .ant-table-cell span {
  /* -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

.radioLabel {
  font-weight: bold;
}

.filterButtons {
  padding: 16px 16px;
}

.filterButtons button:last-child {
  float: right;
}

.filterButtons button:first-child {
  float: left;
}

.rateData {
  float: right;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.001em;
  color: var(--color-blue-03) !important;
  cursor: pointer;
  display: none;
  margin-top: -20px;
}

.intent-topic:hover .rateData {
  display: block !important;
}

.signals-found:hover .rateData {
  display: block !important;
}

.about-section:hover .rateData {
  display: block !important;
}

.socialStrength:hover .rateData {
  display: block !important;
}

.overview:hover .rateData {
  display: block !important;
}

.corelation-score:hover .rateData {
  display: block !important;
}

.webTwoTrafic:hover .rateData {
  display: block !important;
}

.chain-distribution:hover .rateData {
  display: block !important;
}

.topCountries:hover .rateData {
  display: block !important;
}

.techStack:hover .rateData {
  display: block !important;
}

.fundingRounds:hover .rateData {
  display: block !important;
}

.importantContacts:hover .rateData {
  display: block !important;
}

.rateDataTooltip {
  background-color: var(--color-white);
}

.rateImg {
  margin-top: 5px;
  cursor: pointer;
}

.rateSuccess {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.005em;
  color: var(--color-black);
}

.rateSuccess img {
  padding-right: 5px;
}

.copyClipBoard {
  height: 22px !important;
  cursor: pointer;
  position: absolute;
  margin-left: 3px;
}

.telegramWidth {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  float: left;
}

.copied {
  font-size: 12px !important;
  line-height: 20px !important;
  color: var(--color-gray-01) !important;
  padding-left: 5px;
}

.contactTags {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.004em !important;
  color: var(--color-blue-03) !important;
  height: 22px;
  background: var(--color-blue-04);
  border-radius: 4px;
  padding: 3px 6px;
  display: inline-table;
  float: left;
  margin-right: 6px;
  margin-top: 6px;
}

.contactTagsDetails {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  letter-spacing: 0.004em !important;
  color: var(--color-blue-03) !important;
  height: 22px;
  background: var(--color-blue-04);
  border-radius: 4px;
  padding: 3px 6px;
  display: inline-table;
  float: left;
  margin-right: 6px;
  margin-top: 6px;
}

.contactTagsRewriteMargin {
  margin: 10px 10px 0px 0px !important;
}
.tagsTooltip {
  display: table;
}

.tooltipContactTags {
  padding: 3px 6px;
  gap: 8px;
  width: auto;
  height: 22px;
  background: var(--color-blue-04);
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  letter-spacing: 0.004em !important;
  color: var(--color-blue-03) !important;
  display: table-cell;
  float: left;
  margin: 4px;
}

.contactTagsNumber {
  display: inline-table;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 6px;
  gap: 8px;
  width: 26px;
  height: 22px;
  background: var(--color-blue-04);
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.004em !important;
  color: var(--color-blue-03) !important;
  margin-top: 6px;
}

.contactDetailsTags {
  margin-bottom: 30px;
  display: table;
  margin-top: -15px;
}

.tblLogoNameDetails {
  min-height: 32px;
  display: table;
  margin-bottom: -20px;
}

.tblLogoNameDetails img {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  float: left;
}

.tblLogoNameDetails span {
  line-height: 40px;
  padding-left: 20px;
}

.resizeLogoContact {
  width: 32px !important;
  height: 32px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.peopleSearchBar {
  max-width: 330px;
  min-width: 330px;
  height: 32px;
  background: var(--color-white);
  border: 1px solid var(--color-gray-01);
  border-radius: 6px;
}

.contactLogoTop img {
  margin-top: 5px !important;
}

.addNewTag {
  background-color: rgba(0, 0, 0, 0.04) !important;
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.002em;
  color: var(--color-gray-02) !important;
  border: none;
  text-align: left !important;
}

.blockContent {
  text-align: center;
  min-height: 50px;
  margin: 20px;
}

.blockContent .blockIcon {
  width: 32px;
  height: 32px;
  z-index: 1001 !important;
  cursor: pointer;
}

/* .marginTable .ant-pagination.ant-table-pagination .ant-pagination-item-14 {
  display: none !important;
} */

.listModal {
  width: 400px !important;
}

.listModal .ant-modal-content {
  padding: 0px;
}

.funnelModal .ant-modal-content {
  padding: 0px !important;
}

.outreachCampaignWithAIModal .ant-modal-content {
  padding: 15px 0px !important;
}

.listModal .ant-modal-header {
  border-bottom: 1px solid var(--nutrals-700, #ebecf0);
  background: #fff;
}

.listModal .ant-modal-header .ant-modal-title {
  color: var(--nutrals-300, var(--color-gray-02));
  font-size: 14px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.028px;
  padding: 16px;
}

.listModal .ant-modal-close {
  display: none;
}

.listModal .ant-modal-footer {
  padding: 16px;
  border-top: 1px solid var(--nutrals-700, #ebecf0);
  text-align: center !important;
}

.addToList {
  background: var(--primary-600-main, var(--color-blue-03));
  color: var(--nutrals-1000, var(--color-white)) !important;
  font-size: 14px !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500 !important;
  line-height: 25px;
  letter-spacing: 0.028px;
}

.addToList:disabled {
  background-color: var(--nutrals-500, var(--color-gray-01)) !important;
}

.createANewItem {
  color: var(--color-blue-03);
  font-size: 14px;
  font-family: 'Roboto';
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.014px;
  padding: 16px;
  cursor: pointer;
}

.createNewItemNoMargin {
  padding: 0px !important;
  color: var(--color-blue-03) !important;
}

.addNewContainer {
  padding: 0px 16px 16px 16px;
}

.addNewContainerNoMargin {
  padding: 0px !important;
}
.addNewContainer .addNewInput {
  height: 40px;
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--primary-800, #aa93ff);
  background: var(--primary-960, #f2f0ff);
}

.renameListInput {
  height: 40px;
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--primary-800, #aa93ff);
  background: var(--primary-960, #f2f0ff);
}

.rowListItem {
  height: 40px;
  padding: 8px 16px 8px 16px;
}

.rowListItem .listitem {
  color: var(--color-black);
  font-size: 14px;
  font-family: 'Roboto';
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.014px;
  width: 100%;
  display: flex;
  padding: 10px 12px 10px 12px;
}

.rowListItem .selectedList {
  border-radius: 6px;
  border: 1px solid var(--primary-600-main, var(--color-blue-03));
  background: #f2f0ff;
  width: auto;
}

.filterListItem {
  height: 40px;
  padding: 10px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  background: var(--color-white);
}

.selectedListItem {
  border: 1px solid var(--primary-600-main, var(--color-blue-03));
  background: var(--color-blue-04);
}

.selectedListItem .moreOptions {
  display: block !important;
}

.filterListItem:hover {
  border: 1px solid var(--primary-800, var(--color-blue-08));
  background: var(--primary-960, var(--color-blue-04));
}

.filterListItem .moreOptions {
  display: none;
  float: right;
  cursor: pointer;
  line-height: 8px;
  font-weight: bold;
}

.filterListItem:hover .moreOptions {
  display: block;
}

.filterListItem img {
  float: left;
  padding-top: 3px;
  padding-right: 8px;
}

.listOptions span {
  padding: 18px;
  width: 100%;
  display: flex;
  cursor: pointer;
}

.listOptions .ant-divider {
  margin: 0px !important;
}

.removePadding span {
  padding: 0px;
}

.cursorPointer {
  cursor: pointer;
}

.pinElipse {
  background-color: var(--color-white);
  fill: var(--nutrals-1000, var(--color-white));
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: 32px;
  width: 32px;
  border-radius: 50%;
  float: right;
  margin-right: -15px;
  margin-top: 10px;
  text-align: center;
  z-index: 1000;
  cursor: pointer;
}

.movePin img {
  margin-top: 7px;
}

.totalFunding {
  color: var(--color-blue-03) !important;
  font-size: 32px !important;
  line-height: 40px !important;
  font-weight: 700 !important;
}

.alignRight {
  text-align: right;
}

.csvExport {
  margin-left: 5px;
  margin-top: 2px;
}

.hubSpotInstructions {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 32px;
  color: var(--color-black);
  display: block;
}

.borderButton {
  border-bottom: 1px solid var(--color-white-04);
}

.floatLeft {
  float: left;
  margin-right: 20px;
}

.floatLeftImg {
  float: left;
}

.statusKey {
  margin-top: 3px;
}

.showHide {
  font-size: 1.5rem;
  color: black;
  cursor: pointer;
}

.keyInput {
  height: 30px !important;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 100px;
}

.keyInput input {
  background-color: transparent;
}

.eventDetailsDrawer .ant-drawer-header-title {
  display: none;
}

.eventDetailsDrawer .ant-drawer-body {
  padding-top: 0px !important;
}

.ctSearchEvents {
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ctSearchEvents .infoSearch {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: var(--color-blue-05);
  float: left;
  padding-right: 12px;
}

.searchTitleMonth {
  color: var(--nutrals-100, var(--color-gray-06));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.024px;
  line-height: 24px;
  text-align: left;
  margin-bottom: 0px;
}

.country_logo {
  width: 32px;
  height: 32px;
  float: right;
  border-radius: 50%;
  border: 0.5px solid var(--color-gray-01);
  margin-top: -5px !important;
}

.country_logo_calendar {
  width: 22px !important;
  height: 22px;
  border-radius: 50%;
  border: 0.5px solid var(--color-gray-01);
  margin-top: 8px !important;
}

.rezizeLogo {
  width: 22px !important;
}

.logoName {
  margin-bottom: 16px;
}

.logoName .logo {
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.logoName .name {
  overflow: hidden;
  color: var(--tertiary-100, var(--color-gray-08));
  text-overflow: ellipsis;
  font-family: 'Roboto';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.065px;
  vertical-align: sub;
}

.logoName .category {
  overflow: hidden;
  color: var(--tertiary-100, var(--color-gray-08));
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.048px;
  text-align: right;
  width: 100% !important;
  display: block;
  margin-left: -20px;
}

.scrollableContainer {
  max-height: 260px;
  overflow-y: scroll;
  border-radius: 10px;
}

.seeAll {
  color: var(--primary-600-main, var(--color-blue-03));
  font-family: 'Roboto';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.048px;
  width: 100%;
  text-align: center;
  display: block;
  cursor: pointer;
}

.doubleWidth img {
  width: 100%;
}

.tripleWidth img {
  width: 100%;
}

.doubleWidth img.logo {
  width: 100% !important;
}

.tripleWidth img.logo {
  width: 41px !important;
}

.lockIntents {
  opacity: 0.5;
  filter: grayscale(100%);
  cursor: not-allowed;
}

.disableClick {
  cursor: not-allowed;
}

.paywallCustom {
  position: relative !important;
  margin-top: -45%;
  margin-bottom: -30%;
}

.ant-menu-vertical .ant-menu-item {
  height: 45px;
  line-height: 55px;
}

.ant-menu-inline .ant-menu-item {
  height: 45px;
  line-height: 55px;
}

.milestonesSeparator {
  margin: 8px;
}

.contactSalesNoMargin {
  box-shadow: none !important;
}

.columnMinWidth {
  min-width: 150px;
}

.googleBtn {
  margin: 5px 20px 20px 20px;
}

.treeNodeTitle {
  margin-right: 12px;
}

.rangeSeparator {
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding-left: 0px;
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-02);
  float: left;
}

.actionBtns {
  display: flex;
  align-items: center;
}

.actionBtns p {
  margin: 0px 10px 0px 0px;
  /* display: table-cell; */
  /* float: left; */
  /* padding-right: 10px; */
}

.addNewUserSection {
  margin-top: 20px;
  width: 100%;
}

.addNewUserSection p.buyMore {
  float: right;
  margin-right: 20px;
  width: 130px !important;
}

.frmNewUser form {
  width: 100% !important;
}

.frmNewUser form button {
  width: 100% !important;
}

.fullWidth .ant-select {
  width: 100% !important;
}

.customFilterCheckboxes {
  display: table;
}

.goToBtn {
  margin-left: 66px;
  margin-top: 20px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.hubspot_api {
  margin: 0 !important;
}

.hubspot_api_parent {
  display: flex;
}

.removeSelectedCell .ant-table-cell.ant-table-selection-column {
  display: none !important;
}

.addNewUserSection .activeUsers {
  float: left !important;
  margin-left: 20px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: var(--color-blue-05) !important;
  font-weight: bold;
  padding-top: 5px;
}

.moreContacts {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4fa7d7;
  color: white;
  font-weight: bold;
  z-index: 1;
  min-width: fit-content;
}

.more {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 10px;
  border-radius: 40px;
  color: #7043ff;
  background-color: white;
  font-weight: 600;
  margin-left: -6px;
  z-index: 1;
}

.disclaimer {
  cursor: pointer;
}

.disclaimerButton {
  color: var(--color-blue-03);
  font-weight: 600;
  font-style: italic;
}

.disclaimerText {
  max-width: 300px;
  font-style: italic;
  color: #97a0af;
}

.typedText_color {
  overflow: hidden;
  color: var(--primary-600-main, #7043ff);
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.014px;
}

.padding_left_0 {
  padding-left: 0px !important ;
}

.phoneContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.phoneContainer .ant-form-item {
  width: 100%;
}

.phoneContainer .ant-select-selector {
  border-color: transparent !important;
}

.phoneOutreachContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.phoneOutreachContainer .ant-form-item {
  width: 570px;
}

.phoneOutreachContainer .ant-select-selector {
  border-color: transparent !important;
}

/* Hubspot  */
.modalStyleNewDeal .ant-modal-content {
  padding: 5px 24px !important;
}

.newDealForm .ant-row .ant-form-item-row {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 30px 0px;
}

.newDealForm .ant-row .ant-form-item-label {
  text-align: left;
}

.outreachTabs {
  padding-left: 24px;
  height: 100%;

  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
  }
}

/* Outreach Single Contact */
/* .ant-mentions-focused {
  border: none;
  box-shadow: 0 0 0 0px rgba(105, 5, 255, 0.06) !important;
} */

.communities .ant-collapse-header {
  padding: 0px !important;
  align-items: center !important;
}

.communities .ant-collapse-content-box {
  padding: 0px !important;
}

.convrtTabs .ant-tabs-nav {
  /* width: 100%;
  background-color: white; */
  margin: 0px !important;
  /* height: 80px; */
}

.convrtTabs .ant-tabs-tab:hover {
  color: black !important;
}

.convrtTabs .ant-tabs-tab:hover {
  background-color: #fafafafa !important;
}
.convrtTabs .ant-tabs .ant-tabs-tab-btn {
  transition: all 0s !important;
}

.convrtTabs .ant-tabs-nav .ant-tabs-nav-operations {
  display: none;
}

.buyConvrtCard .ant-card-body {
  padding: 24px;
}
.buyConvrtCardAdvanced .ant-card-body {
  padding: 0px !important;
}
.buyConvrtCardAdvanced .ant-card-body {
  padding: 0px !important;
}

.buyConvrtModal .ant-modal-footer {
  display: flex;
  justify-content: center;
}

.buyCreditsInputNumber .ant-input-number .ant-input-number-input {
  text-align: center !important;
}

.outreachCampaignTabs .ant-tabs-nav {
  width: 100%;
  /* height: 75px; */
  height: 50px;
  font-weight: 600;
  /* background-color: white; */
  margin: 0px !important;
  /* background-color: gray; */
  /* height: 80px; */
}

.mainCampaignTable th.ant-table-cell {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: var(--color-blue-05) !important;
}
.mainCampaignTable .ant-table-tbody > tr > td {
  height: 85px;
}

.mainCampaignTable thead {
  height: 64px !important;
}

.mainCampaignTable .ant-table {
  height: 50% !important;
  border-radius: 8px;
}

.mainCampaignTable thead .ant-table-cell {
  background: var(--color-white) !important;
}

.mainCampaignHeader .ant-progress-text {
  color: #7043ff !important;
}

.mainCampaignTable.ant-table-wrapper .ant-table-pagination.ant-pagination {
  display: flex !important;
  position: absolute;
  margin: 10px auto !important;
  bottom: 125px;
  left: 50%;
  flex-wrap: nowrap;

  /* display: flex !important;
  position: relative;
  margin: 10px auto !important;
  bottom: auto;
  left: auto;
  transform: none;
  flex-wrap: nowrap; */
}

.messagesCampaignTable th.ant-table-cell {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: var(--color-blue-05) !important;
}
.messagesCampaignTable .ant-table-tbody > tr > td {
  height: 80px;
}

/* .messagesCampaignTable .ant-table-summary {
  position: fixed;
  bottom: 100px;
  width: 100%;
} */
.messagesCampaignTable .ant-table-summary > tr > td {
  height: 64px;
  padding: 15px 16px;
}

.messagesCampaignTable thead {
  height: 64px !important;
}

.messagesCampaignTable .ant-table {
  height: 50% !important;
  border-radius: 8px;
}

.messagesCampaignTable thead .ant-table-cell {
  background: var(--color-white) !important;
}

.mainCampaignHeader .ant-progress-text {
  color: #7043ff !important;
}

.messagesCampaignTable.ant-table-wrapper .ant-table-pagination.ant-pagination {
  display: flex !important;
  position: absolute;
  margin: 10px auto !important;
  bottom: 10px;
  left: 50%;
  flex-wrap: nowrap;

  /* display: flex !important;
  position: relative;
  margin: 10px auto !important;
  bottom: auto;
  left: auto;
  transform: none;
  flex-wrap: nowrap; */
}

.PricingModal .ant-modal-body {
  overflow-y: scroll;
  height: 80vh;
  overflow-x: hidden;
}

.PricingModal .ant-modal-content {
  background-color: #f1effa;
  padding: 5px 10px 0px 20px;
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.25);
}

.followups_select .ant-select-selector,
.followups_select .ant-select-open {
  border: 0px transparent !important;
  background-color: transparent !important;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  /* box-shadow: 0 !important; */
}

.followups_select .ant-select-selection-item {
  color: #fff !important;
}

.autoCompleteWizard .ant-select-selector {
  height: 40px !important;
  background-color: none !important;
  border-radius: 0%;
  padding: 0px !important;
  background: linear-gradient(322.33deg, rgba(236, 97, 129, 0.16) -38.49%, rgba(49, 107, 255, 0.12) 188.04%);
  border: none !important;
}

.autoCompleteWizard.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: relative !important;
  inset-inline-start: 0px;
  inset-inline-end: 0px;
  width: 100%;
}

.autoCompleteWizard .ant-select-selection-search-input {
  height: 40px !important;
  color: #c5b0fa !important;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 59px;
  letter-spacing: 0.072px;
}

.autoCompleteWizard.ant-select-focused .ant-select-selector {
  transition: none !important;
  outline: 2px solid #015fcc !important;
  border-radius: 2px !important;
}

.autoCompleteWizard .ant-select-selection-placeholder {
  position: absolute !important;
  font-size: 36px;
  /* margin-top: 5px; */
  padding: 5px 0px !important;
  color: #c5b0fa;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 59px;
  letter-spacing: 0.072px;
}

.PricingModal .ant-modal-body {
  overflow-y: scroll;
  height: 80vh;
  overflow-x: hidden;
}

.PricingModal .ant-modal-content {
  background-color: #f1effa;
  padding: 5px 10px 0px 20px;
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.25);
}

.PricingModal .ant-modal-body {
  overflow-y: scroll;
  height: 80vh;
  overflow-x: hidden;
}

.PricingModal .ant-modal-content {
  background-color: #f1effa;
  padding: 5px 10px 0px 20px;
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.25);
}

.messagesTabs .ant-tabs-nav {
  margin: 0px !important;
  z-index: 10000;
}

.messagesTabs .ant-tabs-tab:hover {
  color: black !important;
}

.messagesTabs .ant-tabs-tab:hover {
  /* background-color: #fafafafa !important; */
}

.messagesTabs .ant-tabs .ant-tabs-tab-btn {
  transition: none !important;
}

.messagesTabs .ant-tabs-nav .ant-tabs-nav-operations {
  display: none;
}

.messagesTabs.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom-color: #7043ff !important;
  /* border: none !important; */
  width: 98.8%;
}

.messagesTabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border-color: #d9d9d9 !important;
  border-bottom-color: #7043ff !important;
}

.messagesTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  border-color: #7043ff !important;
  border-bottom-color: white !important;
}

.messagesTabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add {
  border-color: #d9d9d9 !important;
  border-bottom-color: #7043ff !important;
}

/* .messagesTabs.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  background-color: rgba(0, 0, 0, 0.03) !important;
} */

.messagesTabs.ant-tabs .ant-tabs-tab-remove {
  margin-left: 5px !important;
  margin-top: 1px;
  padding: 0;
}

.messagesTabsError .ant-tabs-nav {
  margin: 0px !important;
  z-index: 10000;
}

.messagesTabsError .ant-tabs-tab:hover {
  color: black !important;
}

.messagesTabsError .ant-tabs-tab:hover {
  /* background-color: #fafafafa !important; */
}

.messagesTabsError .ant-tabs .ant-tabs-tab-btn {
  transition: none !important;
}

.messagesTabsError .ant-tabs-nav .ant-tabs-nav-operations {
  display: none;
}

.messagesTabsError.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom-color: #da1818 !important;
  /* border: none !important; */
  width: 98.8%;
}

.messagesTabsError.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border-color: #d9d9d9 !important;
  border-bottom-color: #da1818 !important;
}

.messagesTabsError.ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  border-color: #da1818 !important;
  border-bottom-color: white !important;
}

.messagesTabsError.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add {
  border-color: #d9d9d9 !important;
  border-bottom-color: #da1818 !important;
}

/* .messagesTabs.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  background-color: rgba(0, 0, 0, 0.03) !important;
} */

.messagesTabsError.ant-tabs .ant-tabs-tab-remove {
  margin-left: 5px !important;
  margin-top: 1px;
  padding: 0;
}

.ant-menu-inline .ant-menu-item-disabled .ant-menu-title-content {
  color: var(--color-white) !important;
}

.customCollapseOutreach.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0 !important;
}

.flowPreview.ant-card .ant-card-head {
  padding: 0 !important;
}

.flowPreview.ant-card .ant-card-body {
  height: 100%;
  padding: 0;
  /* background: radial-gradient(rgba(200, 188, 255, 0.2) 1.6px, transparent 0);
  background-size: 18px 18px; */
}

.flowView .react-flow__pane {
  /* top: 70px !important; */
}

.selectSocialAccounts.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  background-color: #f2f0ff !important;
  color: #4722bc;
  font-weight: 500;
  border-radius: 24px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  border: 0;
}
.selectSocialAccounts.ant-select-multiple.ant-select-lg .ant-select-selection-item .ant-select-selection-item-content {
  margin-inline-end: 8px;
}

.selectSocialAccounts.ant-select-multiple.ant-select-lg .ant-select-selection-item-remove {
  color: #4722bc;
}

.betterDivider.ant-divider-vertical {
  border-inline-start: 2px solid rgba(5, 5, 5, 0.06) !important;
  height: 1.2em;
  margin: 0px 12px;
  margin-top: 4px;
}
.autoComplete.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border-bottom: 1px solid #f3f4f6 !important;
  min-width: 250px;
  width: auto;
  border-radius: 0 !important;
}
.autoComplete.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
.autoComplete.ant-select:not(.ant-select-customize-input) .ant-select-selector:focus {
  border-bottom: 1px solid #b649f3 !important;
}
.autoComplete .ant-select-selection-search-input {
  background: linear-gradient(to right, #7144ff, #b649f3) !important;
  background: -webkit-linear-gradient(to right, #7144ff, #b649f3) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: 400;
  font-weight: 400;
}

/* Solid text color on focus for better visibility and functionality */
.autoComplete .ant-select-selection-search-input:focus,
.autoComplete .ant-select-selection-search-input:active {
  background: none !important;
  color: #7144ff !important;
  -webkit-text-fill-color: initial;
}
.autoComplete .ant-select-selection-item {
  background: linear-gradient(to right, #7144ff, #b649f3) !important;
  background: -webkit-linear-gradient(to right, #7144ff, #b649f3) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 400;
  font-weight: 400;
}
.autoComplete .ant-select-selection-search-input {
  max-width: 220px;
  font-size: 14px;
  /* text-overflow: ellipsis; */
  white-space: nowrap;
  overflow: auto;
}
.autoComplete.prompt .ant-select-selection-search-input {
  font-size: 14px;
}
.ant-select-selection-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: #97a0af;
  padding: 0 10px 0px 5px !important;
}
.ant-select-dropdown {
  filter: drop-shadow(0px 10px 90px rgba(0, 0, 0, 0.15));
  border-radius: 8px;
  border: 1px solid #f3f4f6;
  padding: 0;
}
.ant-select-dropdown .ant-select-item-option-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: #000000;
  line-height: 40px;
}
.ant-select-dropdown .ant-select-item {
  background: transparent;
  border-bottom: 1px solid #f3f4f6;
  border-radius: 0;
  background-color: transparent !important;
}
.ant-select-dropdown .ant-select-item-option-content:hover,
.ant-select-dropdown .ant-select-item-option-content:focus {
  background: linear-gradient(to right, #7144ff, #b649f3) !important;
  background: -webkit-linear-gradient(to right, #7144ff, #b649f3) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}
.ant-select-dropdown .ant-select-item:last-child {
  border-bottom: none;
}
.tooltip_section span {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tooltip_section span:hover {
  background: #f2f0ff;
  border-radius: 8px;
}
.full_width {
  width: 100%;
}
:not(.regularTableLists).ant-table-wrapper.Leads_Table .ant-table-pagination.ant-pagination {
  position: relative;
  transform: none;
  left: 0;
  bottom: -10px;
  padding: 30px 0;
}
/* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
.ant-select:not(.ant-select-customize-input) .ant-select-selector:focus {
  border: none !important;
  ox-shadow: none !important;
  background-color: transparent !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
.ant-select:not(.ant-select-customize-input) .ant-select-selector:focus {
  border: none !important;
  ox-shadow: none !important;
  background-color: transparent !important;
}

.ant-select-selection-search-input {
  background: linear-gradient(to right, #7144ff, #b649f3) !important;
  background: -webkit-linear-gradient(to right, #7144ff, #b649f3) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: 600;
}
.ant-select-selection-placeholder {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: #97a0af;
}
.ant-select-dropdown {
  filter: drop-shadow(0px 10px 90px rgba(0, 0, 0, 0.15));
  border-radius: 8px;
  border: 1px solid #f3f4f6;
  padding: 0;
}
.ant-select-dropdown .ant-select-item-option-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: #000000;
  line-height: 40px;
}
.ant-select-dropdown .ant-select-item {
  background: transparent;
  border-bottom: 1px solid #f3f4f6;
  border-radius: 0;
  background-color: transparent !important;
}
.ant-select-dropdown .ant-select-item-option-content:hover,
.ant-select-dropdown .ant-select-item-option-content:focus {
  background: linear-gradient(to right, #7144ff, #b649f3) !important;
  background: -webkit-linear-gradient(to right, #7144ff, #b649f3) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}
.ant-select-dropdown .ant-select-item:last-child {
  border-bottom: none;
} */
.flowPreview.ant-card .ant-card-body > div {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.selectSocialAccounts {
  /* padding: 4px 16px 4px 8px; */
  /* height: 48px; */
  /* border: 1px solid #ebecf0; */
  /* border-radius: 8px; */
}
.Leads_Table table {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}

.Leads_Table .ant-table-body::-webkit-scrollbar {
  width: 1px;
}

/* .ant-table-body::-webkit-scrollbar-track {
  background-color: #e1e6e6;
}

.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: green;
} */

.Leads_Table .ant-tag {
  border: 0;
  padding: 3px 6px;
  background: #f2f0ff;
  border-radius: 4px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.004em;
  color: #7043ff;
}

.Modular_Table_Expand:not(.ModularTableNotExpanded) .ant-table-body {
  min-height: 450px !important;
}
.ViewLeadsList {
  max-width: 75vw;
}

.antSelectAZ .ant-select-selector {
  border-radius: 8px;
  border: 1px solid #dfe1e6;
  background: var(--Color, #fff);
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 10px;
}
.Leads_Table.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}
.Leads_Table .source {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #7043ff;
}
.Leads_Table.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
  background: transparent;
}
.Leads_Table .flex_items {
  display: flex;
  align-items: center;
}
.Leads_Table .flex_items span {
  margin: 0 8px;
}
.Leads_Table .img_group img {
  margin-right: 5px;
}
.Leads_Table .img_group {
  display: flex;
  align-items: center;
  justify-content: left;
  img {
    height: 20px;
    width: 20px;
  }
}
.Leads_Table .img_group .tooltip_section span {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Leads_Table .img_group .tooltip_section span:hover {
  background: #f2f0ff;
}

.Leads_Table .ant-table-pagination.ant-pagination {
  padding: 5px 0px !important;
}

.scheduleAndConfiguration .ant-radio-wrapper .ant-radio {
  align-self: normal;
  padding-top: 3px;
  padding-right: 10px;
}

.scheduleAndConfiguration .ant-radio-wrapper .ant-radio-checked::after {
  animation-duration: 0ms !important;
}

.react-flow__node.selected {
  outline: 1px solid #7043ff;
  border-radius: 12px;
}
.flowBuilder .react-flow__node.selected {
  outline: none !important;
  /* border: 1px solid red; */
}

.react-flow__handle {
  opacity: 0;
}

.react-flow__edge-path {
  stroke: #7043ff !important;
}

.falseEdge .react-flow__edge-path {
  stroke: #df667c !important;
}

.trueEdge .react-flow__edge-path {
  stroke: #2aa58e !important;
}

.customScrollContent::-webkit-scrollbar {
  display: none;
}

.customScrollContent * {
  font-family: 'Roboto', sans-serif !important;
}
@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
  .customScrollContent * {
    font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif !important;
  }
}
/*************leads filter style ***********/
.LeadsFilterModal {
  padding: 0px 20px;
  max-height: 580px;
  overflow-x: auto;
}
.noPadding {
  padding: 0px !important;
}
.LeadsFilterModal .ant-drawer-close {
  display: none;
}
.LeadsFilterModal .ant-drawer-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #051123;
}
.LeadsFilterModal .ant-drawer-title::before {
  content: url(../common/assets/svg/filter_list_icon.svg);
  margin-right: 10px;
  position: relative;
  top: 3px;
}
.LeadsFilterModal .ant-drawer-extra .ant-btn-default {
  border: 0;
  box-shadow: none;
}
.LeadsFilterModal .ant-drawer-header {
  width: calc(100% - 48px);
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}
.LeadsFilterModal .ant-collapse.FilterCollapse > .ant-collapse-item {
  border: 0;
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0;
}
.LeadsFilterModal .ant-collapse.FilterCollapse {
  border: 0;
  background-color: transparent;
}
.ant-collapse.FilterCollapse > .ant-collapse-item:last-child > .ant-collapse-header,
.ant-collapse.FilterCollapse > .ant-collapse-item > .ant-collapse-header {
  border-radius: 0px;
  padding: 20px 0;
  display: flex;
  flex-direction: row-reverse;
}
.LeadsFilterModal .ant-collapse.FilterCollapse > .ant-collapse-item.ant-collapse-item-active .ant-collapse-content {
  border: 0 !important;
  border-radius: 0;
}
.LeadsFilterModal .ant-collapse.FilterCollapse > .ant-collapse-item.ant-collapse-item-active .ant-collapse-arrow svg {
  transform: rotate(270deg) !important;
}
.LeadsFilterModal .ant-collapse.FilterCollapse > .ant-collapse-item .ant-collapse-arrow svg {
  transform: rotate(90deg);
}
.LeadsFilterModal .filter-item {
  display: flex;
  flex-direction: column;
}
.LeadsFilterModal .filter-item .ant-checkbox-wrapper {
  margin-bottom: 25px !important;
}
.LeadsFilterModal .filter-item .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0;
}
.LeadsFilterModal .see_more {
  border: 0 !important;
  box-shadow: none !important;
  text-align: left;
  padding-left: 0;
}
.LeadsFilterModal .filter-item label .ant-checkbox + span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: #051123;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 0;
}
.LeadsFilterModal .filter-item label span.count {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #5e6c84;
}
.ant-collapse.FilterCollapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px 0px 25px;
}
.ant-collapse.FilterCollapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: #5e6c84;
}
.CampaignSummaryContent_tabs .ant-tabs-extra-content button {
  border: 0;
  box-shadow: none;
}
.CampaignSummaryContent_tabs.ant-tabs .ant-tabs-tab-btn {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #5e6c84;
}
.CampaignSummaryContent_tabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 700;
  color: #7043ff !important;
  text-shadow: none !important;
}
.CampaignSummaryContent_tabs.ant-tabs {
  height: 100%;
}
.CampaignSummaryContent_tabs.ant-tabs .ant-tabs-content {
  height: 100%;
}
.CampaignSummaryContent_tabs.ant-tabs .ant-tabs-tabpane {
  height: 100%;
}
.CampaignSummaryContent_tabs.ant-tabs .ant-tabs-tab {
  padding: 12px !important;
  min-width: 170px;
  display: flex;
  justify-content: center;
  margin: 0;
  height: 100%;
}
.CampaignSummaryContent_tabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background: rgba(242, 240, 255, 0.3);
  border-bottom: 2px solid #7043ff;
}
/* .loading-spinner {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #7043ff;
  border-bottom: 6px solid #7043ff;
  border-left: 6px solid #7043ff;
  border-right: 6px solid #dfe1e6;
  padding: 10px;
  width: 80px;
  height: 80px;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
} */

/* Add these styles to your CSS file */

.loading-spinner-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-image {
  width: 85%;
  height: 85%;
  z-index: 1;
  background-color: white;
  border-radius: 50%;
  padding: 18px;
}

.loading-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 8px solid transparent;
  border-top: 8px solid #b649f3; /* Color of the spinner */
  border-radius: 100px;
  background-color: #dfe1e6;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.LeadsModalAndFilters .ant-modal-close {
  display: none;
}
.LeadsModalAndFilters__TopSection .ant-btn-default {
  border: 0;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  margin-right: 22px;
}
.ant-modal.LeadsModalAndFilters .ant-modal-content {
  padding: 0;
}

.LeadsModalAndFilters .LeadsFilterModal .ant-collapse.FilterCollapse {
  width: 100%;
}

.leadsSider.ant-layout {
  background-color: white !important;
}
.leadsSider.ant-layout .ant-layout-sider-zero-width-trigger {
  top: -66px !important;
  left: -30px;
  background: none;
  color: #7043ff;
  transition: none !important;
  display: none;
}

.customNodeSelect.ant-select .ant-select-selector {
  /* background-color: red !important;
   */
  border-radius: 8px;
  height: 22px !important;
  align-items: center;
}
/* .customNodeSelect.ant-select-dropdown {
  width: 240px !important;
} */

.importLeadsModal .ant-modal-content {
  border-radius: 30px !important;
}

.socialConnectionModal .ant-modal-content {
  border-radius: 12px !important;
}

.socialConnectionForm .ant-form-item {
  margin: 0;
}

.refineListModal .ant-modal-content {
  border-radius: 30px !important;
}

/**********chat css************/
.ant-layout-sider.chat_sider {
  background: #ffffff;
  padding: 23px;
  &::-webkit-scrollbar {
    width: 2px;
  }
}
.ant-layout-sider.chat_sider .ant-layout-sider-trigger {
  position: absolute;
  bottom: 50%;
  width: auto !important;
  right: 0px;
  background: transparent;
}
.ant-layout-sider.chat_sider_account .ant-layout-sider-trigger {
  right: -13px;
}
.ant-layout-sider.chat_sider .ant-layout-sider-trigger .anticon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #dfe1e6;
  border-radius: 50%;
}

.ant-layout-sider.chat_sider .ant-layout-sider-trigger .anticon svg {
  color: #7043ff;
}
.ant-collapse.chat_channels_collapse {
  border: 0;
  background: transparent;
  padding: 0;
  margin-top: 25px;
}
.ant-collapse.chat_channels_collapse .ant-collapse-header {
  padding: 0;
  justify-content: center;
  /* flex-direction: row-reverse; */
  /* justify-content: space-around; */
  align-items: center;
  position: relative;
}
.ant-collapse.chat_channels_collapse .ant-collapse-content {
  border: 0px;
}
.ant-collapse.chat_channels_collapse .ant-collapse-header .ant-collapse-header-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
}
.ant-collapse.chat_channels_collapse > .ant-collapse-item {
  border: 0;
  margin-bottom: 12px;
}
.ant-collapse.chat_channels_collapse > .ant-collapse-item.ant-collapse-item-active .ant-collapse-arrow svg {
  transform: rotate(270deg) !important;
}
.ant-collapse.chat_channels_collapse > .ant-collapse-item .ant-collapse-arrow svg {
  transform: rotate(90deg);
}
.ant-collapse.chat_channels_collapse .ant-collapse-content-box {
  /* padding-right: 0; */
  margin-top: 2px;
  padding: 0px 16px;
}
.ant-collapse.chat_channels_collapse .view_all_channel_item .ant-collapse-content-box {
  display: none;
}
.chat_channels_collapse .ant-menu-inline {
  border: none !important;
}
.view_all_channel_item .ant-collapse-expand-icon {
  display: none !important;
}
.view_all_channel_item span.all {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.view_all_channel_item span.all.m_2 {
  margin-left: 2px;
  margin-right: 2px;
}
.chat_channels_collapse .ant-menu-light.ant-menu-inline .ant-menu-item {
  padding: 1px;
  padding-left: 1px !important;
  padding-right: 1px !important;
}
.chat_channels_collapse .ant-menu-light.ant-menu-inline .ant-menu-item:hover {
  background: linear-gradient(to top, #b649f3, #7144ff);
  background: -webkit-linear-gradient(to right, #b649f3, #7144ff);
  padding: 1px;
  padding-left: 1px !important;
  padding-right: 1px !important;
  border-radius: 10px;
}
.chat_channels_collapse .ant-menu-item .ant-menu-title-content .menu_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 5px;
}
.ant-menu-inline .ant-menu-item-disabled .ant-menu-title-content {
  color: #d0d0d0 !important;
}
.chat_channels_collapse .ant-menu-light .ant-menu-item-selected {
  background: linear-gradient(to top, #b649f3, #7144ff);
  background: -webkit-linear-gradient(to right, #b649f3, #7144ff);
  padding: 1px;
  padding-left: 1px !important;
  padding-right: 1px !important;
  border-radius: 10px;
}
.chat_channels_collapse .ant-menu-item .ant-menu-title-content {
  border: 1px solid transparent;
  background: #ffffff;
}
.chat_channels_collapse .ant-menu-item .ant-menu-title-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
  height: 100%;
  border-radius: 10px;
}
.chat_channels_collapse .ant-menu-item .ant-menu-title-content .left .ant-avatar {
  margin-right: 10px;
}
.chat_channels_collapse .ant-menu-item .ant-menu-title-content .right {
  display: flex;
  align-items: center;
  flex: 0.2;
}
.chat_channels_collapse .ant-menu-item .ant-menu-title-content .reconnect {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: #0575b3;
  text-decoration: underline;
}
.chat_channels_collapse .ant-menu-item .ant-menu-title-content .reconnect:hover {
  color: #223641;
}
.chat_channels_collapse .ant-menu-item .ant-menu-title-content .count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  background: #7043ff;
  border-radius: 66.6667px;
  font-weight: 500;
  font-size: 10px;
  color: #ffffff;
  line-height: 11px;
}
.chat_channels_collapse .ant-menu .ant-menu-item-selected {
  color: #000000 !important;
}
.chat_channels_collapse .ant-menu-item .ant-menu-title-content .left .ant-avatar {
  position: relative;
  overflow: visible;
  background: transparent;
}
.chat_channels_collapse .ant-menu-item.online .ant-menu-title-content .left .ant-avatar:after {
  content: '';
  height: 6px;
  width: 6px;
  background-color: #50e2ba;
  position: absolute;
  z-index: 9999;
  bottom: 0;
  border-radius: 50%;
  right: 0;
}
.outOfSync.ant-avatar:after {
  background-color: #ffc700 !important;
}
.chat_channels_collapse .ant-menu-item.offline .ant-menu-title-content .left .ant-avatar img,
.chat_channels_collapse .ant-menu-item.offline .ant-menu-title-content .left .nav-text {
  opacity: 0.5;
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nav-text {
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-width: 1600px) {
  .nav-text {
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.chat_channels_collapse .ant-menu-item.offline .ant-menu-title-content .left .ant-avatar:after {
  content: '';
  height: 6px;
  width: 6px;
  background-color: #e25050;
  position: absolute;
  z-index: 9999;
  bottom: 0;
  border-radius: 50%;
  right: 0;
}
.ant-collapse.chat_channels_collapse .ant-collapse-header {
  background: #ffffff;
  border-radius: 10px;
  padding: 8px;
}
.ant-collapse.chat_channels_collapse > .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
  /* background: #faf9ff; */
  /* border-radius: 10px; */
}
/* .ant-collapse.chat_channels_collapse > .ant-collapse-item.ant-collapse-item-active .ant-collapse-header::after {
  content: '';
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  bottom: -1px;
  background: linear-gradient(to top, #b649f3, #7144ff);
  background: -webkit-linear-gradient(to right, #b649f3, #7144ff);
  z-index: -1;
  border-radius: 10px;
} */

.ant-layout-sider.ant-layout-sider-collapsed.chat_sider
  .ant-collapse.chat_channels_collapse
  > .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header::after {
  display: none;
}
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider .ant-collapse.chat_channels_collapse .ant-collapse-header {
  padding: 0;
}
/*.ant-layout-sider.ant-layout-sider-collapsed.chat_sider {*/
/*  width: 120px !important;*/
/*  min-width: 120px !important;*/
/*  max-width: 120px !important;*/
/*}*/
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider
  .ant-collapse.chat_channels_collapse
  .view_all_channel_item
  span.all {
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: capitalize;
  margin-top: 5px;
}
.activeOptionsTiles {
  border: 1px solid #7144ff !important;
}
/* .ant-layout-sider.ant-layout-sider-collapsed.chat_sider .hide_collapse {
  display: none;
} */
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider .para {
  display: none;
}
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider .hide_collapse .view_all_channel > div {
  flex-direction: column;
}
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider .hide_collapse .view_all_channel {
  justify-content: center;
}
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider .hide_collapse .hide {
  display: none;
}
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider .hide_collapse .view_all_channel p {
  margin: 5px 0 0;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider
  .ant-collapse.chat_channels_collapse
  .ant-collapse-header
  .ant-collapse-header-text
  .right,
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider
  .ant-collapse.chat_channels_collapse
  .ant-collapse-header
  .ant-collapse-expand-icon {
  display: none;
}
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider
  .ant-collapse.chat_channels_collapse
  .ant-collapse-header
  .ant-collapse-header-text
  .left {
  flex-direction: column;
  font-size: 12px;
}
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider
  .ant-collapse.chat_channels_collapse
  .ant-collapse-header
  .ant-collapse-header-text
  .left
  img {
  margin-right: 0;
}
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider
  .ant-collapse.chat_channels_collapse
  .ant-collapse-header
  .ant-collapse-header-text {
  margin-right: 0;
  justify-content: center;
  padding: 8px;
  border-radius: 10px;
}
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider
  .ant-collapse.chat_channels_collapse
  .ant-collapse-header
  .ant-collapse-header-text
  .left
  .count,
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider .ant-collapse.chat_channels_collapse .ant-collapse-content {
  display: none;
}
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider .hidebtn_collapse {
  display: none;
}

.ant-layout-sider.ant-layout-sider-collapsed.chat_sider
  .ant-collapse.chat_channels_collapse
  > .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header
  .ant-collapse-header-text {
  background: linear-gradient(to top, #b649f3, #7144ff);
  background: -webkit-linear-gradient(to right, #b649f3, #7144ff);
  padding: 1px;
  border-radius: 10px;
}
.ant-layout-sider.ant-layout-sider-collapsed.chat_sider
  .ant-collapse.chat_channels_collapse
  > .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header
  .ant-collapse-header-text
  .left {
  border: 1px solid transparent;
  padding: 8px;
  background: #faf9ff;
  border-radius: 10px;
  width: 100%;
  flex: 0.8;
}

.ant-tabs.ChatTabsSidebarContent_tabs > .ant-tabs-nav::before {
  border-bottom: 1px solid #dfe1e6;
}
.ant-tabs.ChatTabsSidebarContent_tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs.ChatTabsSidebarContent_tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  display: none;
}
.ant-tabs.ChatTabsSidebarContent_tabs > .ant-tabs-nav .ant-tabs-nav-list {
  justify-content: space-around;
  width: 100%;
}
.ant-tabs.ChatTabsSidebarContent_tabs .ant-tabs-tab-btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #051123;
}
.ant-tabs.ChatTabsSidebarContent_tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600;
  text-shadow: none;
  color: #051123 !important;
}
.ant-tabs.ChatTabsSidebarContent_tabs .ant-tabs-tab-btn span {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #5e6c84;
}
.ant-tabs.ChatTabsSidebarContent_tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn span {
  color: #051123;
}
.search_select .ant-select-selector {
  border: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}
.chat_receipt_box__bottomSection .ant-select {
  margin-right: 8px;
}
.select_dots {
  width: 6px;
  height: 6px;
  background: #ff0000;
  border-radius: 50%;
  display: inline-flex;
  margin-right: 5px;
}
.chat_receipt_box__bottomSection .ant-tag {
  padding: 4px 12px;
  background: #f2f0ff;
  border-radius: 8px;
  border: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.004em;
  color: #7043ff;
}
.chat_receipt_box__bottomSection .chat_receipt_box__bottomSection__more {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.004em;
  color: #7043ff;
}
/* .activeBox {
  border: 1px solid #7144ff !important;
  background: #faf9ff !important;
} */
.activeBox {
  position: relative;
  z-index: 1;
}
.activeBox:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(to top, #b649f3, #7144ff);
  background: -webkit-linear-gradient(to right, #b649f3, #7144ff);
  z-index: -1;
  border-radius: 10px;
}
.chat_receipt_box .chat_receipt_box__main {
  background: #ffffff;
  padding: 16px;
  border-radius: 10px;
}
.chat_receipt_box.activeBox .chat_receipt_box__main {
  background: #faf9ff;
  margin: 1px;
}
.ant-select.high_select .ant-select-arrow {
  color: rgba(0, 0, 0);
}
.MessageComponent__bottomSection__left .ant-select .ant-select-selector .ant-select-selection-search-input {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: #97a0af;
}
.MessageComponent__bottomSection__left .ant-select .ant-select-selector {
  background: url(../common/assets/png/Enrich_img.png) no-repeat !important;
  background-position: left 10px center !important;
}
.MessageComponent__bottomSection__left
  .ant-select-single.ant-select-lg
  .ant-select-selector
  .ant-select-selection-item {
  padding-left: 20px !important;
}
.MessageComponent__bottomSection__right button {
  min-width: 40px !important;
  height: 40px;
  border-radius: 16px !important;
  background: linear-gradient(107.68deg, #7144ff 14.56%, #b649f3 125.16%);
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.03);
}
.MessageComponent__bottomSection__right button svg {
  transform: rotate(-45deg);
  font-size: 20px;
}
.MessageComponent__bottomSection__middle {
  position: relative;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.MessageComponent_sider {
  padding: 24px 0;
  background: #ffffff !important;
  line-height: normal;
  border-left: 1px solid #dfe1e6;
}
.ant-layout-sider-zero-width.MessageComponent_sider,
.ant-layout-sider-zero-width.MessageComponent_sider .ant-layout-sider-children {
  padding: 0 !important;
}
.MessageComponent_sider .ant-layout-sider-children {
  padding: 0 24px;
  overflow: hidden;
  overflow-y: auto;
}

.flowPreviewCarousel {
  position: absolute;
  bottom: 140px;
  width: 90%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 120px;
}
.flowPreviewCarousel.ant-carousel .slick-slider {
  height: 120px !important;
}
.flowPreviewCarousel .slick-list {
  height: 120px !important;
  border-radius: 12px;
  box-shadow: 0px 0px 1px 3px rgba(0, 0, 0, 0.05) !important;
}

.flowPreviewCarousel.ant-carousel .slick-dots li button {
  background: black !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #7043ff;
  opacity: 1;
}

.ant-carousel .slick-dots li button {
  background: #635757;
}

.selectPriority.ant-select .ant-select-selector {
  border-radius: 8px !important;
  border-color: #dfe1e6;
}

.messageComponentInput.ant-input[disabled] {
  background-color: white;
}

.messageComponentInput.ant-input {
  padding: 7px 60px 7px 11px !important;
}

.groupedDialogs {
  /* width: calc(100% + 7px); */
  padding-right: 7px;

  background-color: transparent;
  &::-webkit-scrollbar {
    width: 2px !important;
  }
}

.groupedDialogs.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px 0px 12px 0px;
}

.groupedDialogs.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.groupedDialogs.ant-collapse-borderless > .ant-collapse-item {
  border-color: transparent;
}

.profileSiderGlobal {
  padding: 0px !important;
  background: #ffffff !important;
  line-height: normal;
  border-left: 1px solid #dfe1e6;
  overflow-y: hidden;
}

.profileSiderGlobal::-webkit-scrollbar {
  width: 2px !important;
}

.profileSiderGlobal .ant-layout-sider-children {
  padding: 0 12px !important;
}

.profileSiderDescription.ant-descriptions .ant-descriptions-row > th {
  padding-bottom: 0px;
}
.profileSiderDescription.ant-descriptions .ant-descriptions-item-container .ant-descriptions-item-content {
  font-weight: 600;
}

.campaignsSwitch.ant-switch {
  min-width: 38px !important;
}

.campaignStatsCard.ant-card .ant-card-body {
  padding: 16px 24px;
  max-height: 165px;
}

.leadsInsightsDivider.ant-divider-vertical {
  height: 32px;
  margin: 0px 15px;
  border-inline-start: 1px solid #dfe1e6;
}

.leadsInsightStatistics.ant-statistic .ant-statistic-title {
  color: #5e6c84;
  line-height: 18px;
  text-align: left;
  /* font-size: 13px; */
}

.leadsInsightStatistics.ant-statistic .ant-statistic-content {
  color: #1a1a1a;
  font-weight: 600;
  font-family: Roboto;
  font-size: 21px;
  text-align: justified;
}

/* .campaignsPageTable.ant-table-wrapper .ant-table-pagination.ant-pagination {

} */

.campaignsPageTable.ant-table-wrapper .ant-spin-nested-loading > div > .ant-spin {
  /* position: relative !important; */
  top: auto;
}

.mainSidebar.ant-layout-sider {
  position: fixed !important;
  z-index: 2000;
  height: 100%;
}

/* .custom-switch .ant-switch-inner .ant-switch-inner-checked {
  position: relative;
}

.custom-switch .ant-switch-inner .ant-switch-inner-unchecked {
  margin-top: -22px;
  margin-inline-start: 10px;
  margin-inline-end: -10px;
} */

.custom-switch .ant-switch-handle::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: url('common/assets/svg/mask_gray.svg') no-repeat center center;
  background-size: 74%;
  background-color: white;
}
.custom-switch.ant-switch.ant-switch-checked .ant-switch-handle::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: url('common/assets/svg/mask_purple.svg') no-repeat center center;
  background-size: 74%;
  background-color: white;
}

.custom-switch:hover .ant-switch-handle::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: url('common/assets/svg/mask_dark_gray.svg') no-repeat center center;
  background-size: 74%;
  background-color: white;
}

.custom-switch.pending .ant-switch-handle::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: url('common/assets/svg/clock_circle_white_bg.svg') no-repeat center center !important;
  background-size: 100% !important;
  background-color: white;
}

.addLeadsModal.ant-card-hoverable {
  box-shadow: none;
  /* border: 2px solid transparent; */
  /* border: 2px solid #dfe1e6; */

  /* border-image: transparent 2; */
  /* border-radius: 5px; Add border-radius */
  /* border-width: 2px;
  border-style: solid; */
}

/* LINEAR GRADIENT BORDER EXAMPLE: */
.addLeadsModal.ant-card-bordered {
  border: none;
}
.addLeadsModal.ant-card-hoverable:before {
  box-shadow: none;
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px;
  background: #dfe1e6;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.addLeadsModal.ant-card-hoverable:hover::before,
.addLeadsModalSelected.ant-card-hoverable::before {
  box-shadow: none;
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px;
  background: linear-gradient(45deg, #7043ff, #9452fe, #b860fd, #be53fe, #c447ff, #d42cff, #df19ff);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
/* LINEAR GRADIENT BORDER EXAMPLE: */

/* .groupsSidebar.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: white !important;
}

.groupsSidebar.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: white;
  outline: 1px solid #7043ff;
}

.groupsSidebar.ant-menu-inline .ant-menu-item {
  height: 35px !important;
  padding-right: -20px !important;
  padding-left: 35px !important;
  width: 87%;
}

.groupsSidebar.ant-menu-light .ant-menu-item-selected {
  background: white !important;
  outline: 1px solid #7043ff;
} */

/* Base styles */
.groupsSidebar.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: white !important;
}

.groupsSidebar.ant-menu-inline .ant-menu-item {
  position: relative; /* Ensure pseudo-elements position correctly */
  height: 35px !important;
  padding-right: 10px !important; /* Adjust as needed */
  padding-left: 35px !important;
  width: 87%;
}

/* Hover effect */
.groupsSidebar.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: #fcfbff;
}

/* Selected item */
.groupsSidebar.ant-menu-light .ant-menu-item-selected {
  background: #fcfbff !important;
}

/* Gradient border pseudo-elements */
.groupsSidebar.ant-menu-inline .ant-menu-item::before,
.groupsSidebar.ant-menu-inline .ant-menu-item::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1px;
  background: white; /* Fallback background */
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

/* Hover state gradient border */
.groupsSidebar.ant-menu-inline .ant-menu-item:hover::before,
.groupsSidebar.ant-menu-inline .ant-menu-item:hover::after {
  background: linear-gradient(45deg, #7043ff, #9452fe, #b860fd, #be53fe, #c447ff, #d42cff, #df19ff);
}
/* Gradient border pseudo-elements */
.groupsSidebar.ant-menu-inline .ant-menu-item.ant-menu-item-selected::before,
.groupsSidebar.ant-menu-inline .ant-menu-item.ant-menu-item-selected::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1px;
  background: white;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.groupsSidebar.ant-menu-inline .ant-menu-item.ant-menu-item-selected::before,
.groupsSidebar.ant-menu-inline .ant-menu-item.ant-menu-item-selected::after {
  background: linear-gradient(45deg, #7043ff, #9452fe, #b860fd, #be53fe, #c447ff, #d42cff, #df19ff);
}

.leadsSider .ant-collapse.FilterCollapse > .ant-collapse-item > .ant-collapse-header {
  padding: 22px 0px !important;
}

.builderSider .ant-tabs-nav {
  width: 100% !important;
  /* height: 100% !important; */
}

/* height */
.builderSider.ant-tabs {
  height: 100% !important;
}
.builderSider.ant-tabs .ant-tabs-tabpane {
  height: 100% !important;
}
.builderSider.ant-tabs .ant-tabs-content {
  height: 100% !important;
}
.builderSider.ant-tabs .ant-tabs-content-holder {
  height: 100% !important;
}

.builderSider .ant-tabs-tab {
  display: block; /* centers text inside tabs */
  flex: 1;
  text-align: center;
  font-weight: 600;
}

.builderSider .ant-tabs-nav > div:nth-of-type(1) {
  display: unset !important;
  width: 100% !important;
}

.builderSider.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0px !important;
}

.builderSider.ant-tabs .ant-tabs-ink-bar {
  /* background: linear-gradient(45deg, #991c2f, #15c81e) !important; */
  background: linear-gradient(45deg, #7144ff, #b649f3) !important;
  height: 3px;
}

.builderSider .ant-tabs-nav-operations {
  /* Fix ant design flickering issue */
  display: none !important;
}

.elementsTab {
  max-height: 77vh;
  overflow-y: scroll;
}

.elementsTab.ant-collapse::-webkit-scrollbar {
  width: 0px;
}

.elementsTab.ant-collapse .ant-collapse-header {
  padding: 12px 0px !important;
}

.elementsTab.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-block: 0;
  padding: 0;
}

/* .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav-operations-hidden {
  all: revert !important;
} */

.platformsSelectSetup.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 26px !important;
  line-height: 1.6 !important;
  border: none !important;
  background-color: #ebecf0;
  color: #5e6c84;
  border-radius: 8px;
}

.platformsSelectSetup .ant-tag.ant-tag-has-color .anticon-close {
  color: #5e6c84 !important;
}

.platformsSelectSetup .ant-select-selector {
  border-color: #97a0af !important;
}
.platformsSelectSetup.ant-select .ant-select-arrow {
  color: #051123 !important;
}

.mentionsTabs.ant-tabs .ant-tabs-tab {
  padding: 0px 0px 5px 0px;
}
.mentionsTabs.ant-tabs-top > .ant-tabs-nav {
  /* width: 50% !important; */
  margin: 0px 0px 5px 0px;
}

.mentionsError.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: red !important;
}

.mentionsError.ant-tabs .ant-tabs-ink-bar {
  background: red !important;
}
.mentionsError.ant-tabs,
.mentionsError.ant-tabs:hover {
  color: red !important;
}

.loginTimer.ant-statistic {
  line-height: 0.24 !important;
  width: fit-content;
  margin: 0px 3.5px;
  span {
    font-weight: 600;
  }
}

.groupsFilterTags.ant-tag .ant-tag-close-icon {
  color: #7043ff !important;
}

.groupsFilterRedTags.ant-tag .ant-tag-close-icon {
  color: #ff5c5c !important;
}

.onboardingSteps.ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  /* background-color: red !important;
   */
  min-height: 80px;
}

.onboardingSteps.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #7043ff;
  border: 1px solid #7043ff;
  outline: 1px solid #7043ff;
}

.onboardingSteps.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white;
}

.onboardingSteps.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  font-weight: 600;
  background-color: white;
  outline: 1px solid #7043ff;
  span {
    color: #7043ff !important;
  }
  /* border-color: #7043ff80; */
}

.onboardingSteps.ant-steps .ant-steps-item-icon .ant-steps-icon {
  font-weight: 600;
  color: #7043ff;
}
.onboardingSteps.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: white;
  border-color: #7043ff80;
  outline: 1px solid #7043ff80;
}

.onboardingSteps.ant-steps .ant-steps-item-icon .ant-steps-icon {
  color: #7043ff80;
}

.onboardingSteps.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-weight: 600;
}

.onboardingSteps.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-weight: 600;
  color: #7043ff;
}

/* LINEAR GRADIENT BORDER EXAMPLE: */
.smallSocialCard.ant-card-bordered,
.selectableCards.ant-card-bordered,
.selectableCardsSelected.ant-card-bordered {
  border: none;
}
.smallSocialCard.ant-card-hoverable:before,
.selectableCards.ant-card-hoverable:before,
.selectableCardsSelected.ant-card-hoverable:before {
  box-shadow: none;
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px;
  background: #dfe1e6;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.smallSocialCard.ant-card-hoverable:hover,
.selectableCards.ant-card-hoverable:hover,
.ant-card-hoverable.ant-card-hoverable:hover {
  box-shadow: none;
}
.smallSocialCard.ant-card-hoverable:hover::before,
.selectableCards.ant-card-hoverable:hover::before,
.selectableCardsSelected.ant-card-hoverable:before {
  box-shadow: none;
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px;
  background: linear-gradient(45deg, #7043ff, #9452fe, #b860fd, #be53fe, #c447ff, #d42cff, #df19ff);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.onboardingICP.ant-tag {
  background: linear-gradient(to right, #7144ff, #b649f3) !important;
  background: -webkit-linear-gradient(to right, #7144ff, #b649f3) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  border: 1px solid rgba(5, 5, 5, 0.06) !important ;
}

.onboardingICP.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border-bottom: 1px solid #f3f4f6 !important;
  min-width: 120px;
  width: 'fit-content';
  padding-right: 20px;
  width: auto;
  border-radius: 0 !important;
}
.editICP.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-bottom: none !important;
}
.onboardingICP.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
.onboardingICP.ant-select:not(.ant-select-customize-input) .ant-select-selector:focus {
  border-bottom: 1px solid #b649f3 !important;
}
.onboardingICP .ant-select-selection-search-input {
  background: linear-gradient(to right, #7144ff, #b649f3) !important;
  background: -webkit-linear-gradient(to right, #7144ff, #b649f3) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: 400;
  font-weight: 400;
}

/* Solid text color on focus for better visibility and functionality */
.onboardingICP .ant-select-selection-search-input:focus,
.onboardingICP .ant-select-selection-search-input:active {
  background: none !important;
  color: #7144ff !important;
  -webkit-text-fill-color: initial;
}
.onboardingICP .ant-select-selection-item {
  background: linear-gradient(to right, #7144ff, #b649f3) !important;
  background: -webkit-linear-gradient(to right, #7144ff, #b649f3) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 400;
  font-weight: 400;
}
.onboardingICP .ant-select-selection-search-input {
  max-width: 240px;
  font-size: 14px;
  /* text-overflow: ellipsis; */
  white-space: nowrap;
  overflow: auto;
}
.onboardingICP.prompt .ant-select-selection-search-input {
  font-size: 14px;
}

.onboardingICP.ant-input-textarea-show-count .ant-input-data-count {
  top: -24px !important;
}

.ICPTable.ant-table-wrapper .ant-table-thead > tr > td,
.ICPTable.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: white !important;
}

.ICPTable.ant-table-wrapper .ant-table {
  /* border-inline-end: 1px solid red !important; */
  border: 1px solid #dfe1e6 !important;
  border-radius: 8px !important;
}

/* .ICPTable.ant-table-content {
  overflow-x: hidden !important;
} */

.whiteTooltip.ant-tooltip {
  color: yellow !important;
  background-color: green !important;
  --antd-arrow-background-color: 'red' !important;
}

.whiteTooltip.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.whiteTooltip.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.whiteTooltip.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: green !important;
}

.cardEmpty.ant-card .ant-card-body {
  height: 100%;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  display: flex;
  align-items: center;
}

.ant-table-wrapper .ant-table-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.hoverableScrollbarTable.ant-table-wrapper .ant-table-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* .hoverableScrollbarTable.ant-table-wrapper .ant-table-body::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 3px;
} */

.hoverableScrollbarTable.ant-table-wrapper .ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}

.hoverableScrollbarTable.ant-table-wrapper .ant-table-body::-webkit-scrollbar-track {
  background-color: transparent;
}

.hoverableScrollbarTable.ant-table-wrapper .ant-table-body {
  overflow: hidden !important;
}

.hoverableScrollbarTable.ant-table-wrapper:hover .ant-table-body {
  overflow: auto !important;
}
.GroupsModalCustom.ant-modal .ant-modal-content {
  padding: 0px !important;
  overflow: hidden;
}

.template-preview-modal.ant-modal .ant-modal-content {
  padding: 0px !important;
  overflow: hidden;
}
.learning-video-modal.ant-modal .ant-modal-content {
  padding: 0px !important;
  overflow: hidden;
}
.learning-video-modal.ant-modal .ant-modal-header {
  margin-bottom: 0px !important;
}
.OnboardingCard.ant-card > .ant-card-body {
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 12px !important;
}

.CardNoPadding.ant-card > .ant-card-body {
  padding: 0;
}
.ModalNoPadding.ant-modal > .ant-modal-content {
  padding: 0;
}
.CardFullHeight.ant-card > .ant-card-body {
  height: 100%;
}

.OnboardingCard.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  /* color: black !important; */
}
.OnboardingCard.ant-tabs .ant-tabs-ink-bar {
  /* background: linear-gradient(45deg, #991c2f, #15c81e) !important; */
  background: linear-gradient(45deg, #7144ff, #b649f3) !important;
  height: 3px;
}

.OnboardingCard .ant-tabs-nav-operations {
  /* Fix ant design flickering issue */
  display: none !important;
}

/* Base styles */
.onboardingTrack.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: white !important;
}

.onboardingTrack.ant-menu-inline .ant-menu-item {
  position: relative; /* Ensure pseudo-elements position correctly */
  height: 35px !important;
  padding-right: 10px !important; /* Adjust as needed */
  padding-left: 10px !important;
  width: 87%;
}

/* Hover effect */
.onboardingTrack.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: #fcfbff;
}

/* Selected item */
.onboardingTrack.ant-menu-light .ant-menu-item-selected {
  background: #fcfbff !important;
}

/* Gradient border pseudo-elements */
.onboardingTrack.ant-menu-inline .ant-menu-item::before,
.onboardingTrack.ant-menu-inline .ant-menu-item::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1px;
  background: white; /* Fallback background */
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

/* Hover state gradient border */
.onboardingTrack.ant-menu-inline .ant-menu-item:hover::before,
.onboardingTrack.ant-menu-inline .ant-menu-item:hover::after {
  background: linear-gradient(45deg, #7043ff, #9452fe, #b860fd, #be53fe, #c447ff, #d42cff, #df19ff);
}
/* Gradient border pseudo-elements */
.onboardingTrack.ant-menu-inline .ant-menu-item.ant-menu-item-selected::before,
.onboardingTrack.ant-menu-inline .ant-menu-item.ant-menu-item-selected::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1px;
  background: white;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.onboardingTrack.ant-menu-inline .ant-menu-item.ant-menu-item-selected::before,
.onboardingTrack.ant-menu-inline .ant-menu-item.ant-menu-item-selected::after {
  background: linear-gradient(45deg, #7043ff, #9452fe, #b860fd, #be53fe, #c447ff, #d42cff, #df19ff);
}

.onboardingTrack.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  padding-left: 5px !important;
}

.CollectLeadsModal.ant-modal .ant-modal-content {
  padding: 0px;
}

.aiResponderDrawer .ant-drawer-close {
  position: absolute;
  top: 18px;
  left: -54px;
  background: #00000033;
  color: white;
  font-size: 22px;
  padding: 4px;
}
.aiResponderDrawer .ant-drawer-body {
  background-color: #f3f4f6;
}

.mentionsDrawer.ant-mentions > textarea::-webkit-scrollbar {
  width: 2px;
}

.custom-radio-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.custom-radio {
  margin: 0;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
}

.custom-radio:hover {
  border-color: #7043ff;
}

.custom-radio.custom-disabled {
  cursor: not-allowed;
}

.custom-radio.custom-disabled:hover {
  border-color: #d9d9d9;
}

.crown-icon {
  margin-left: 6px;
}

.convrtCardSelected.ant-card-bordered {
  border: none;
}
.convrtCard.ant-card-hoverable:before,
.convrtCardSelected.ant-card-hoverable:before {
  box-shadow: none;
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1px;
  background: #dfe1e6;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.convrtCard.ant-card-hoverable:hover,
.ant-card-hoverable.ant-card-hoverable:hover {
  box-shadow: none;
}

.convrtCard.ant-card-hoverable:hover::before,
.convrtCardSelected.ant-card-hoverable:before {
  box-shadow: none;
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1px;
  background: linear-gradient(45deg, #7043ff, #9452fe, #b860fd, #be53fe, #c447ff, #d42cff, #df19ff);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.collapseNoPadding.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
  height: 100%;
}

.collapseNoPadding.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 0px;
}

.respondersDropdown:hover {
  color: #7043ff !important;
}
.outreachHelpFloater .ant-float-btn-body {
  width: 52px;
  height: 52px;
  background: linear-gradient(180deg, #7144ff 0%, #b649f3 100%);
  border-radius: 10px !important;
  box-shadow: 0px 0.61px 1.84px 0px #0000004d;
  box-shadow: 0px 2.46px 4.92px 1.84px #00000026;
}
.outreachHelpFloater.ant-float-btn {
  inset-inline-end: 30px !important;
}
.outreachHelpFloater .ant-float-btn-icon {
  font-size: 24px !important;
  width: 24px !important;
}
.article-drawer .ant-drawer-footer {
  border-top: none !important;
}
.ant-skeleton-button.customVideoSkelton {
  width: 190px !important;
  height: 170px !important;
}
.quill-editor {
  border: none !important; /* Disable border */
  background: transparent; /* Optional: Make background transparent */
}

.ql-container .ql-snow {
  border: none !important;
  background: transparent;
  height: 100%;
}
.ql-container.ql-snow {
  border: none !important;
  background: transparent;
  height: 100%;
}

.radioUpgrade .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background: #7043ff !important;
  span {
    color: white !important;
  }
}

/* .pricingCard.ant-card-hoverable {
  border: 2px solid #dfe1e6;
}

.pricingCard.ant-card-hoverable:hover {
  border: 2px solid #dfe1e6 !important;
  cursor: default;
}

.pricingCardMostPopular.ant-card-hoverable {
  border: 2px solid #7043ff !important;
  cursor: default;
}

.pricingCardMostPopular.ant-card-hoverable:hover {
  border: 2px solid #7043ff !important;
  cursor: default;
} */

.pricingCardSelected.ant-card-bordered {
  border: none;
}
.pricingCard.ant-card-hoverable:before,
.pricingCardSelected.ant-card-hoverable:before {
  box-shadow: none;
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px;
  background: #dfe1e6;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.pricingCard.ant-card-hoverable:hover,
.ant-card-hoverable.ant-card-hoverable:hover {
  box-shadow: none;
}

.pricingCardMostPopular.ant-card-hoverable::before,
.pricingCardMostPopularSelected.ant-card-hoverable:before {
  box-shadow: none;
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px;
  background: linear-gradient(45deg, #7043ff, #9452fe, #b860fd, #be53fe, #c447ff, #d42cff, #df19ff);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.pricingCardMostPopular.ant-card-hoverable:hover::before,
.pricingCardMostPopularSelected.ant-card-hoverable:before {
  box-shadow: none;
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  /* padding: 2px; */
  background: #7043ff;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.pricingCard.ant-card-hoverable:hover::before,
.pricingCardSelected.ant-card-hoverable:before {
  box-shadow: none;
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  /* padding: 2px; */
  background: #7043ff;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.pricingCard.ant-card-bordered,
.pricingCardMostPopular.ant-card-bordered {
  border: none !important;
}
.ROISlider.ant-slider .ant-slider-track {
  background-color: #7043ff;
}

/* .ROISlider.ant-slider .ant-slider-handle {
  width: 20px;
  height: 20px;
}

.ROISlider.ant-slider .ant-slider-handle::after {
  width: 15px;
  height: 15px;
} */

.ExtraFeaturesModal.ant-modal .ant-modal-content {
  background-color: #f2f0ff !important;
}

.customFont {
  font-family: 'FbSpoilerPasEng', sans-serif !important;
}

.Modular_Table_Expand.ant-drawer {
  z-index: 100000 !important;
}

.ant-tooltip {
  z-index: 100001 !important;
}
.ant-drawer {
  z-index: 1000 !important;
}
.ant-drawer.learning-center-drawer .ant-drawer-mask {
  background: none !important;
}

.settingsProgress.ant-progress-line {
  margin-bottom: 0px;
  font-size: 8px;
}

.settingsProgress.ant-progress .ant-progress-inner {
  background-color: #dfe1e6;
}
.filterSavedContent .ant-collapse-content .ant-collapse-content-box {
  padding: 0px;
}
.filterSavedContent .ant-collapse-header {
  padding: 0px !important;
}
.settingsProgress.ant-progress .ant-progress-bg {
  background-color: #7043ff;
}
.websiteInputSignup .ant-input-group .ant-input-group-addon {
  background: var(--Color, #fff);

  border: 1px solid rgba(223, 225, 230, 0.6);
}

.groupsFiltersCollapse {
  filter: blur(1.2px);
  /* filter: blur(1.2px);
  cursor: not-allowed; */
}

.groupsFiltersCollapse.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-block: 1px !important;
}

/* .whiteTooltip {
  background-color: white;
} */

.breadcrumb.ant-breadcrumb {
  line-height: 1 !important;
}

.custom-success-message .ant-message-notice-content {
  background-color: #e2fff4 !important;
  border: 1px solid #48bb78;
}

.custom-info-message .ant-message-notice-content {
  background-color: #e8f8ff !important;
  border: 1px solid #19bcfe;
}

.custom-warning-message .ant-message-notice-content {
  background-color: #fde8ea !important;
  border: 1px solid #ef1a2c;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.custom-error-message .ant-message-notice-content {
  background-color: #fde8ea !important;
  border: 1px solid #ef1a2c;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.custom-error-message .ant-message-notice .ant-message-notice-content {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.newFilterModal .ant-modal-content {
  border-radius: 24px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  display: flex;
  width: 560px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid var(--Nutrals-800, #f3f4f6);
  background: var(--Color, #fff);
  box-shadow: 0px 24px 32px 0px rgba(0, 0, 0, 0.15);
}
.newFilterTitle {
  color: #000;

  /* Headline/Headline small */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}
.newFIlterInput {
  display: flex;
  padding: 6px 14px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Linear-2, #7144ff);
  background: var(--Color, #fff);
  width: 100%;
}
.filterGroupsSider::-webkit-scrollbar-track {
  width: 50px;
  height: 50px;
}
.groupsTable .ant-table {
  border: 1px solid #dfe1e6;
  border-radius: 8px;
}

.groupsTable .ant-table-tbody > tr > td {
  height: 61px;
  padding: 0px 16px;
}

.groupsTable .ant-table-thead > tr > th {
  height: 55px;
}

.customHover > .ant-popover-arrow {
  top: 135px !important;
}
.settingsCustomHover > .ant-popover-arrow {
  top: 345px !important;
}

.Modular_Table_Expand .ant-drawer-close {
  position: absolute;
  top: 18px;
  left: -54px;
  background: #00000033;
  color: white;
  font-size: 22px;
  padding: 4px;
}
.Modular_Table_Expand .ant-drawer-body {
  background-color: #f3f4f6;
  z-index: 10000;
}
.removeLeadsVisible {
  opacity: 1;
  transition: opacity 0.3s;
}
.removeLeadsHidden {
  opacity: 0.5;
  transition: all 0.3s;
}
.meeting-book-option {
  color: black !important;
  background-color: white !important;
  -webkit-text-fill-color: black !important;
  opacity: 1;
  /*padding: 0;*/
}

.meeting-book-option:hover {
  color: #b649f3 !important;
  -webkit-text-fill-color: #b649f3 !important;
  background-color: white !important;
  opacity: 1;
  cursor: pointer;
}
.meeting-book-option:focus {
  cursor: text;
}
.meetingBookSelect .ant-select-selector {
  cursor: pointer !important;
}
.meetingBookSelect .ant-select-selection-item .meetingBookThreeDots {
  display: none;
  opacity: 0;
  pointer-events: none;
}
.ChatTabsPlatformHeader_select .ant-select-selector {
  height: 25px !important;
}
.SkeletonButtonChatTabs {
  display: flex !important;
  align-items: center;
  min-width: 20px !important;
}
.ChatTabs_ChatTabsPlatformHeader__qRgXZ span.ant-select-selection-item {
  align-self: center;
}
.skeletonCustom .ant-skeleton-input {
  width: 110px !important;
  min-width: 110px !important;
  max-width: 110px !important;
}
.popoverNoArrow .ant-popover-arrow {
  display: none;
}
.cancelAnytimeCustom {
  font-weight: 300 !important;
}
.modalNoPadding .ant-modal-content {
  display: flex;
  width: 560px;
  height: 224px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid var(--Nutrals-800, #f3f4f6);
  background: #fff;
  box-shadow: 0px 24px 32px 0px rgba(0, 0, 0, 0.15);
}
.modalNoPadding .ant-modal-footer {
  width: 100%;
}
pre {
  margin: 0;
}
.unified_contact_image {
  background-color: var(--color-gray-01);
  color: white;
}
.b2bFormMileSlider.ant-input-number .ant-input-number-input {
  text-align: center !important;
}
.hubspotSyncSider .ant-drawer-header {
  padding: 24px 0 !important;
  border-bottom: none !important;
  background: #fff;
}

.hubspotSyncSider .ant-drawer-body {
  background: #f3f4f6 !important;
}

.Modular_Table_Expand .connectPlusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Modular_Table_Expand .tblLogoName {
  display: flex;
  align-items: center;
}
.Modular_Table_Expand .tblLogoName .badge,
.Modular_Table_Expand .tblLogoName .badgeFirst,
.Modular_Table_Expand .tblLogoName .badgeSecond,
.Modular_Table_Expand .tblLogoName .badgeThird {
  width: 24px;
  height: 24px;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modular_Table_Expand .tblLogoName .badge {
  background: #7043ff;
}

.Modular_Table_Expand .tblLogoName .badgeFirst {
  border: 2px solid #50e2ba !important;
}
.Modular_Table_Expand .tblLogoName .badgeFirst .badgeFirst {
  background: #50e2ba;
}

.Modular_Table_Expand .tblLogoName .badgeSecond {
  border: 2px solid #7043ff !important;
}

.Modular_Table_Expand .tblLogoName .badgeSecond .badgeSecond {
  background: #7043ff;
}

.Modular_Table_Expand .tblLogoName .badgeThird {
  border: 2px solid #ff4fe6 !important;
}

.Modular_Table_Expand .tblLogoName .badgeThird .badgeThird {
  background: #ff4fe6;
}

.Modular_Table_Expand .tblLogoName .badge img {
  margin-right: 1px;
}

.Modular_Table_Expand .tblLogoName span {
  letter-spacing: 0.005em;
}
.Modular_Table_Expand .tblLogoName .mainPicture {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 0.5px solid #939cad45;
}
.Modular_Table_Expand .tblLogoName .ant-badge {
  border-radius: 50%;
  border: 2px solid #7143ff;
  height: 52px;
  width: 52px;
}

.Modular_Table_Expand .tblFieldText.company_field {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.Modular_Table_Expand .tblFieldText.company_field img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border-radius: 15px;
}

.Modular_Table_Expand .tblFieldText span {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.005em;
  color: var(--color-gray-02);
}

.Modular_Table_Expand .tblFieldText span.asLink {
  color: var(--color-blue-03) !important;
}

.Modular_Table_Expand .tblFieldText a {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.005em;
  color: var(--color-blue-03);
}
.Modular_Table_Expand .tblFieldText span {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.005em;
  color: var(--color-gray-02);
}

.Modular_Table_Expand .tblFieldText span.asLink {
  color: var(--color-blue-03) !important;
}

.Modular_Table_Expand .tblFieldText a {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.005em;
  color: var(--color-blue-03);
}
.disabled-row {
  background-color: rgb(240, 240, 240);
  pointer-events: none;
}

.articleWrapper {
  min-height: 150px !important;
  max-height: 150px !important;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.articleWrapper .articleCard {
  min-height: 120px !important;
  max-height: 120px !important;
  width: 90%;
  border-radius: 0px !important;
}
.articleWrapper .articleCard .ant-card-body {
  padding: 0px !important;
  margin: 0px !important;

  min-height: 120px !important;
  max-height: 120px !important;
  display: flex;
}
.articleWrapper .articleCard .ant-card-body .ant-image {
  min-height: 120px !important;
  max-height: 120px !important;
}
.articleWrapper .articleCard .ant-card-body .articleContent {
  width: calc(100% - 120px);
  display: flex;
  padding: 10px;
  height: 120px;
  overflow: hidden;
  flex-direction: column;
}
.articleWrapper .articleCard .ant-card-body .articleContent .articleContentHeading {
  color: var(--Nutrals-200, #091e42);
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.articleWrapper .articleCard .ant-card-body .articleContent .articleContentSub {
  color: var(--Nutrals-200, #091e42);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.08px;
  text-overflow: ellipsis;
  white-space: normal; /* Allow multiple lines */
  overflow: hidden;
  display: -webkit-box; /* Enable multi-line truncation */
  -webkit-line-clamp: 2; /* Limit to two lines */
  -webkit-box-orient: vertical; /* Set box to be vertical */
  line-clamp: 2;
  line-height: 1.2; /* Reduce line height to minimize space between lines */
}

.articleWrapper .articleCard .ant-card-body .articleContent .articleContentEnd {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.articleContentLink:hover {
  color: var(--Nutrals-200, blue);
}
.selectFiltersHeatboxSmall .ant-select-selector {
  border-radius: 3px !important;
  height: 30px !important;
  max-height: 30px !important;
  overflow: hidden !important;
  width: 125px !important;
  background-color: #f5f5f5 !important;
  color: black !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0px 10px !important;
  font-weight: bold !important;
}
.selectFiltersHeatboxSmall .ant-select-selector .ant-select-selection-item {
  font-weight: bold !important;
}
.selectFiltersHeatboxDropdownSmall .ant-select-item {
  padding: 0px !important;
}
.selectFiltersHeatboxDropdownSmall .ant-select-item .ant-select-item-option-content {
  color: black !important;
  padding: 0px 20px !important;
}
.selectFiltersHeatboxDropdownSmall .ant-select-item .ant-select-item-option-content:hover {
  color: black !important;
  background: transparent !important;
  -webkit-text-fill-color: rgb(59, 59, 59);
}
.selectFiltersHeatboxSmall:hover {
  border: none !important;
  box-shadow: none !important;
}
.heatboxFilterRect {
  border-radius: 3px;
  background: #f9fafb;
  width: 30%;
  height: 42px;
}
.heatboxFilterRect .ant-select-selector {
  height: 100% !important;
  border: none !important;
}
.heatboxFilterRect .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.heatboxFilterCirc {
  border-radius: 30px;
  border: 1px solid #000;
  background: #f9fafb;
  height: 42px;
  width: 30%;
  box-shadow: unset !important;
}
.heatboxFilterCirc .ant-select-selector {
  height: 100% !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: unset !important;
}
.heatboxFilterCirc.ant-select-focued {
  box-shadow: unset !important;
}
.heatboxFilterCirc .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.custom-range-picker {
  height: 40px !important; /* Increase height */
}
.custom-range-picker .ant-picker-range-separator {
  padding: 0px !important;
}
.custom-range-picker .ant-picker-input input {
  height: 100%;
}
