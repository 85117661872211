/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: var(--scrollbar-width, 4px);
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: var(--scrollbar-track, #ffffff);
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb, #d1d5db);
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #bec3c9;
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #d1d5db #ffffff;
}

/* Custom slow spinner animation */
@keyframes slow-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.slow-spin {
  animation: slow-spin 3s linear infinite;
}

/* Fix for components being cut off at bottom */
.content-area {
  padding-bottom: 32px;
}
