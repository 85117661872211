.buttonDiv {
  width: 24px;
  height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonSpan {
  width: 160px;
  height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: #091e42;
  flex: none;
  order: 1;
  flex-grow: 1;
  text-align: left;
}
