@keyframes explode {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  20% {
    transform: scale(1.05);
    opacity: 0.9;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
.explode-animation {
  animation: explode 0.5s forwards;
}
