/* Default font for all browsers */
*::not(.twp) {
  font-family: 'Roboto', sans-serif, -apple-system !important;
}

@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
  * {
    font-family: BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif, -apple-system !important;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  // Add your specific styling here
}

.loading {
  background-color: white;
  min-height: 250px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 20px;
  // z-index: 99999;
}

.prepare {
  display: flex;
  flex-direction: column;
  width: 600px !important;
  gap: 10px;
}
