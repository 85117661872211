.container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  height: 100%;
  align-items: stretch;
  justify-content: center;
  padding: 10px 0px;
}

.tableContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
  min-width: 45%;
  //   height: 75vh;
}

.tableHeader {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: fit-content;

  p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.4;
  }

  span {
    font-size: 12px;
    color: #6b778c;
    line-height: 1.4;
  }
}

.statsContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.stats {
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: space-between;
  background-color: #faf9ff;
  padding: 12px;
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #e1e4e8;

  p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }

  span {
    font-size: 12px;
    color: #6b778c;
    white-space: nowrap;
    line-height: 0;
    align-items: center;
    display: flex;
  }

  //   &::before {
  //     content: '';
  //     position: absolute;
  //     top: -1px;
  //     left: -1px;
  //     right: -1px;
  //     bottom: -1px;
  //     border-radius: 8px;
  //     padding: 1px;
  //     background: linear-gradient(to bottom, #7043ff, #b64af3);

  //     -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  //     mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  //     -webkit-mask-composite: xor;
  //     mask-composite: exclude;
  //   }
}

.statsOverLimit {
  @extend .stats;
  border-color: red;
}

// Table styles
:global(.ant-table-wrapper) {
  flex: 1;
  min-height: 0; // Allow table to scroll

  :global(.ant-table) {
    height: 100%;

    :global(.ant-table-thead > tr > th) {
      background: #fafbfc;
      font-weight: 600;
      padding: 12px 16px;
    }

    :global(.ant-table-tbody > tr > td) {
      padding: 12px 16px;
    }
  }
}

.centerArea {
  width: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  color: #7043ff;
  text-align: center;
  padding: 0 8px;
  //   flex-shrink: 0; // Prevent shrinking
}

.tableWrapper {
  padding: 0px 16px 16px 16px;
}

.table {
  border: 1px solid #dfe1e6;
  border-radius: 4px;
  :global(.ant-table.ant-table-small .ant-table-tbody > tr > td) {
    background-color: white;
  }
  :global(.ant-table-thead > tr > th) {
    // background-color: #f9fafb !important;
    background-color: white !important;
  }
  :global(.ant-table-wrapper .ant-table-thead > tr > td) {
    // background-color: #f9fafb !important;
    background-color: white !important;
  }
  :global(.ant-table-thead > tr > td) {
    // background-color: #f9fafb !important;
    background-color: white !important;
  }
  :global(tr.ant-table-expanded-row > td) {
    // background-color: #f9fafb !important;
    background-color: white !important;
  }

  :global(.ant-table-pagination.ant-pagination) {
    position: relative !important;
    bottom: 0px !important;
    border-radius: 0px 0px 4px 4px;
    padding: 10px 0px;
    // left: 0 !important;
    // right: 0 !important;
    // margin: 0 auto !important;
    background-color: white;
  }
}

.centeredText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  color: #7043ff;
  img {
    cursor: pointer;
    width: 30px;
    height: 30px;
    &:hover {
      opacity: 0.8;
    }
  }
}

.table_row_light,
.originalColor {
  background-color: #ffffff;
}

.table_row_dark,
.highlightedColor {
  background-color: #edeaff;
}
.table_row_dark:hover td,
.highlightedColor:hover td {
  background-color: #e7e2ff !important;
}

.profileUrl {
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    cursor: pointer;
    text-decoration: underline;
    color: #7043ff;
    &:hover {
      opacity: 0.8;
    }
  }
}
