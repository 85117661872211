.content {
  .title {
    border-bottom: 1px solid #f0f0f0;
    padding: 20px 20px;
    p {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }
  }
}

.contentWrapper {
  padding: 20px 20px;
}

.modal {
  :global(.ant-modal-content) {
    padding: 0px;
  }

  :global(.ant-modal-body) {
    padding: 0px;
  }

  :global(.ant-modal-title) {
    font-size: 20px;
    font-weight: 600;
    color: #1a1a1a;
  }
}

.inputGroup {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 10px;
  color: #1a1a1a;
}

.inputError {
  border-color: #ff4d4f;

  &:focus,
  &:hover {
    border-color: #ff4d4f;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.1);
  }
}

.errorText {
  margin-top: 4px;
  display: block;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.cancelButton {
  border-radius: 20px;
  padding: 0 20px;
  height: 38px;
  border: 1px solid #e0e0e0;
  color: #333;

  &:hover {
    border-color: #d0d0d0;
    color: #000;
  }
}

.generateButton {
  background-color: #7043ff;
  border-color: #7043ff;
  border-radius: 20px;
  padding: 0 28px;
  height: 38px;
  font-weight: 500;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
}
